import { Tabs } from 'antd'
import { BlocForm, Header, Helmet, Table } from 'components'
import { useState } from 'react'
import { history } from 'utils'

function ManagementPage(props) {
    const { option } = props.match.params
    const [active, setActive] = useState(option || '1')

    const handleChange = key => {
        setActive(key)
        history.replace(`/admin/management/${key}`)
    }

    return (
        <div className='management-page'>
            <Helmet title='Gestion du site' />
            <Header title='Gestion du site' subtitle='Administration' />

            <Tabs activeKey={active} onChange={handleChange}>
                <Tabs.TabPane tab='Utilisateurs' key={1}>
                    <Table instance='users' />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Partenaires' key={2}>
                    <Table instance='partners' />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Lieux' key={3}>
                    <Table instance='locations' />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Coin de rue' key={4}>
                    <Table instance='street_corner' />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Bloc modifiable' key={5}>
                    <BlocForm />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default ManagementPage
