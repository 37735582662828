import { Header, Helmet } from "components";

function AboutPage() {
  return (
    <div className="about-page">
      <Helmet title="À propos" />
      <Header title="À propos" />

      <div className="about-wrap">
        <p>
          L'équipe mobile de médiation et d'intervention sociale (ÉMMIS) a pour
          mission d'agir rapidement dans l'espace public pour répondre aux
          situations de conflits, de détresse ou de cohabitation touchant les
          personnes vulnérables ou en difficulté. L'équipe mobile de médiation
          et d'intervention sociale (ÉMMIS) a pour mission d'agir rapidement
          dans l'espace public pour répondre aux situations de conflits, de
          détresse ou de cohabitation touchant les personnes vulnérables ou en
          difficulté.
        </p>
        <h3> 8 intervenants psychosociaux</h3>
        <p>
          Huit intervenants psychosociaux civils font partie de ce projet pilote
          qui est développé pour la première fois par une municipalité
          québécoise. L’équipe mobile de médiation et d’intervention sociale
          agit sur sa propre initiative ou répond à des appels du SPVM ou
          d’organismes communautaires.
        </p>

        <h4>Quatre types de services sont offerts :</h4>

        <ul>
          <li>Une assistance mobile et rapide;</li>
          <li>
            Le référencement et l’accompagnement vers les ressources
            communautaires et institutionnelles;
          </li>
          <li>
            La médiation et la résolution de conflits liés à l’occupation ou à
            l’usage de l’espace public;
          </li>
          <li>La présence et la prévention directe dans l’espace public.</li>
        </ul>

        <h3>L’intervention sociale pour désamorcer les conflits</h3>

        <p>
          L’équipe mobile utilise l’intervention sociale et la médiation pour
          désamorcer les situations volatiles, référer les individus à risque
          vers les ressources communautaires appropriées et appliquer des
          stratégies de prévention tout en assurant une présence visible dans
          les lieux publics.
          <br />
          <br />
          En ce sens, elle souhaite réaliser plusieurs transformations positives
          au bénéfice des citoyens, des quartiers et des réseaux communautaires,
          comme :
        </p>
        <ul>
          <li>L’amélioration de la connaissance des ressources disponibles;</li>
          <li>L’augmentation de l’utilisation des ressources disponibles;</li>
          <li>La réduction de l’intervention policière;</li>
          <li>La réduction des plaintes envers les personnes vulnérables;</li>
          <li>
            L’augmentation du sentiment de sécurité de la population, incluant
            celui des personnes vulnérables.
          </li>
        </ul>
        <p>
          En collaboration avec la Société de développement social, l’ÉMMIS est
          disponible 15 heures par jour, de 9 h à minuit, 7 jours par semaine.
        </p>
        <br />
        <br />
        <p>
          Contactez l’équipe mobile à :{" "}
          <a href="mailto: >emmis@montreal.ca">emmis@montreal.ca</a>
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
