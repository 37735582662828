import { Form, Input, Button } from "antd";
import { Icon } from "components";
import { useEffect, useState } from "react";
import { CustomBlockService } from "services";
import { Feedback, textareaProps } from "utils";

function BlocForm() {
  const [form] = Form.useForm();
  const [data, setData] = useState({});

  const fetchBlock = async () => {
    const customBlock = await CustomBlockService.get(1);
    setData(customBlock);
  };

  useEffect(() => {
    fetchBlock();
  }, []);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const feedback = new Feedback.Async();

    try {
      await CustomBlockService.update({ id: 1, ...values });
      feedback.success("Changements effectués avec succès");
    } catch (e) {
      feedback.error();
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={() => handleSubmit()}>
      <Form.Item label="Titre" name="title">
        <Input />
      </Form.Item>
      <Form.Item label="Texte" name="content">
        <Input.TextArea {...textareaProps()} />
      </Form.Item>
      <div className="buttons">
        <Button className="blue" htmlType="submit">
          <Icon>save</Icon>
          Sauvegarder les changements au bloc
        </Button>
      </div>
    </Form>
  );
}

export default BlocForm;
