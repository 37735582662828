import moment from 'moment'

export function getDateRange(preset) {
    const now = moment()
    switch (preset) {
        case 'last-week':
            return [now.clone().subtract(1, 'week'), now]
        case 'last-month':
            return [now.clone().subtract(1, 'month'), now]
        case 'last-year':
            return [now.clone().subtract(1, 'year'), now]
        case 'current-year':
            return [now.clone().startOf('year'), now.clone().endOf('year')]
        case 'all':
            return null
        default:
            throw new Error(`The preset '${preset}' does not exist`)
    }
}
