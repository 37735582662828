import { Form, InputNumber } from 'antd'
import { rules } from 'utils'
import { AGE, LANGAGE, ORIGIN, SEXE } from 'constants/formvalues'

function InterventionStep4(props) {
    const { activeStep } = props

    return (
        <div className='intervention-step-4'>
            <div className='inputs-wrap-1'>
                <h3>Sexes</h3>
                <div className='actions-container'>
                    {SEXE.map(sexe => {
                        return (
                            <Form.Item
                                rules={activeStep === 3 ? [rules.required] : null}
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
            </div>
            <div className='inputs-wrap-2'>
                <h3>Langues parlées</h3>
                <div className='actions-container'>
                    {LANGAGE.map(sexe => {
                        return (
                            <Form.Item
                                rules={activeStep === 3 ? [rules.required] : null}
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
            </div>
            <div className='inputs-wrap-3'>
                <h3>Origines Culturelles</h3>
                <div className='actions-container'>
                    {ORIGIN.map(sexe => {
                        return (
                            <Form.Item
                                rules={activeStep === 3 ? [rules.required] : null}
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
            </div>
            <div className='inputs-wrap-4'>
                <h3>Àges</h3>
                <div className='actions-container'>
                    {AGE.map(sexe => {
                        return (
                            <Form.Item
                                rules={activeStep === 3 ? [rules.required] : null}
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InterventionStep4
