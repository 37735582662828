/**
 * Service : Users
 * ---
 * Interaction with the DB to manipulate users.
 */

import { updateAbilitiesFor } from 'constants/abilities'
import { header, objectToFormData, queryAPI, serialize } from 'utils'

export const UserService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    list,
    get,
    update,
    create,
    del,
}

async function login(email, password) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({
            user: {
                email: email,
                password: password,
            },
        }),
    }

    return await queryAPI('login', params, false, (response, user) => {
        const token = response.headers.get('Authorization')
        updateAbilitiesFor(user)
        return [user, token]
    })
}

async function logout() {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI('logout', params)
}

async function forgotPassword(user) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({
            user: user,
        }),
    }

    return await queryAPI('password', params)
}

async function resetPassword(user) {
    const params = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({
            user: user,
        }),
    }

    return await queryAPI('password', params)
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`users?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`users/${id}`, params)
}

async function update(user) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            user: user,
        }),
    }
    return await queryAPI(`users/${user.id}`, params, true)
}

async function create(user) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            ...header(),
        },

        body: objectToFormData({ user }),
    }

    return await queryAPI(`users`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`users/${id}`, params, true)
}
