import AuthButton from "../components/AuthButton";

import { Helmet, Header } from "components";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import { set } from "slices/filters.slice";
import { getDateRange } from "utils";
import moment from "moment";

function LogIn() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters.content);
  useEffect(() => {
    if (!filters)
      dispatch(
        set({
          ...filters,
          date: [
            moment().startOf("year").format("DD-MM-YYYY"),
            moment().endOf("year").format("DD-MM-YYYY"),
          ],
          datePreset: "current-year",
        })
      );
  }, []);

  const user = useSelector((state) => state.user);
  if (user.logged) return <Redirect to="/admin/home" />;

  return (
    <div className="home-page">
      <Helmet title="Connexion au portail ÉMMIS" />
      <Header title="Connexion au portail ÉMMIS" noLog />
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <div
          className="homepage"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>
            Bienvenue sur le portail de l'équipe mobile de médiation et
            d'intervention sociale (ÉMMIS). Connectez-vous ci-dessous pour
            accéder au formulaire et aux données. En cas de problème d'accès,
            merci de contacter l'équipe mobile à :{" "}
            <a href="mailto:emmis@montreal.ca">emmis@montreal.ca</a>
          </p>
          <div className="user-actions login-btn ">
            <AuthButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
