import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet, Header, Icon, Map, CountUp } from 'components'
import parse from 'html-react-parser'
import { CustomBlockService, StatsService } from 'services'
import { transformObjectData } from 'utils'
import { Bar, Donut } from 'components/Charts'
import { useStats } from 'hooks'
import _ from 'lodash'
import { TYPE_FORM } from 'constants/formvalues'
import { Spin } from 'antd'

export const Bloc = props => {
    const { className, icon, title, descr, link, blocs, custom, noBG, children: content } = props

    const Title = props => {
        if (link)
            return (
                <h3>
                    <Link to={link}>
                        {props.children}
                        <Icon>to</Icon>
                    </Link>
                </h3>
            )

        return (
            <h3>
                <span>{props.children}</span>
            </h3>
        )
    }

    return (
        <div className={`bloc ${custom ? 'is-custom' : ''} ${noBG ? 'no-bg' : ''} ${className}`}>
            <Title>
                <Icon>{icon}</Icon>
                <i>{title}</i>
            </Title>
            {custom ? (
                <div className='custom-content-wrap'>{content}</div>
            ) : (
                <>
                    <p>{descr}</p>
                    <div className='content-wrap'>
                        {blocs ? (
                            <div className='data-blocs'>
                                {blocs.map((bloc, i) => (
                                    <span className={`of-${blocs.length}`} key={i}>
                                        <CountUp percent={bloc.percent}>{bloc.data || 0}</CountUp>
                                        <small>{bloc.label}</small>
                                    </span>
                                ))}
                            </div>
                        ) : (
                            content || <></>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

function HomePage() {
    const MonthyInterventions = props => {
        let { data } = props
        let tMonthlyData = [
            { label: 'Jan.', value: 0, id: 1 },
            { label: 'Fév.', value: 0, id: 2 },
            { label: 'Mar.', value: 0, id: 3 },
            { label: 'Avr.', value: 0, id: 4 },
            { label: 'Mai', value: 0, id: 5 },
            { label: 'Jui.', value: 0, id: 6 },
            { label: 'Jul.', value: 0, id: 7 },
            { label: 'Aoû.', value: 0, id: 8 },
            { label: 'Sep.', value: 0, id: 9 },
            { label: 'Oct.', value: 0, id: 10 },
            { label: 'Nov,', value: 0, id: 11 },
            { label: 'Déc.', value: 0, id: 12 },
        ]
        _.forEach(
            data,
            tData => (tMonthlyData.find(tmd => tmd.id === tData.id).value = tData.value)
        )

        tMonthlyData = tMonthlyData.reduce(
            (obj, item) => Object.assign(obj, { [item.label]: item.value }),
            {}
        )
        return Object.values(tMonthlyData).reduce((prev, next) => {
            return prev + next
        }, 0) > 0 ? (
            <Bar height={200} data={transformObjectData(tMonthlyData, 'x', 'y')} />
        ) : (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0.6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                Aucune données disponibles pour le moment.
            </div>
        )
    }

    const InterventionsType = props => {
        let { data } = props
        _.forEach(data, tData => {
            const item = TYPE_FORM.find(iData => iData.id == tData.id)
            tData.label = item && item.label ? item.label : 'Sans provenance'
            return
        })

        return Object.keys(data).length > 0 ? (
            <Donut data={data} height={200} />
        ) : (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0.6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                Aucune données disponibles pour le moment.
            </div>
        )
    }

    const TotalThisYear = props => {
        let data = props.data[0]?.total_count
        return typeof data === 'number' ? (
            <CountUp cName='stats-interventions'>{data}</CountUp>
        ) : (
            <CountUp cName='stats-interventions'>{0}</CountUp>
        )
    }

    const { Filters, GraphBloc } = useStats('datas')
    const [customBlock, setCustomBlock] = useState({})
    const [mapData, setMapData] = useState(undefined)
    const [isReady, setIsReady] = useState(false)

    const goodFetch = async () => {
        const [block, points] = await Promise.all([
            CustomBlockService.get(1),
            StatsService.load('map'),
        ])
        setMapData(points)
        setCustomBlock(block)
        setIsReady(true)
    }

    useEffect(() => {
        setIsReady(false)
        goodFetch()
        return () => {
            setIsReady(false)
            setMapData(undefined)
            setCustomBlock({})
        }
    }, [])
    return (
        <div className='home-page'>
            <Helmet title='Accueil' />
            <Header title='Accueil' subtitle='Tableau de bord' filters={Filters} showPrint />
            <Spin spinning={!isReady}>
                <div className='main-content-wrap'>
                    <div className='main-content-1'>
                        <Bloc
                            title="Nombre d'interventions mensuelles"
                            icon='demography_page'
                            descr={
                                isReady ? undefined : 'Aucune données disponibles pour le moment.'
                            }
                        >
                            {isReady && (
                                <GraphBloc dataKey='monthly_count' graph={MonthyInterventions} />
                            )}
                        </Bloc>
                        {isReady && customBlock && (customBlock?.tittle || customBlock?.content) && (
                            <Bloc className='right' title={customBlock?.title} icon='infos' custom>
                                <p>{parse(customBlock?.content || '')}</p>
                            </Bloc>
                        )}
                    </div>

                    <div className='main-content-2'>
                        <Bloc
                            title='Interventions par type'
                            icon='demography_page'
                            descr={
                                isReady ? undefined : 'Aucune données disponibles pour le moment.'
                            }
                        >
                            {isReady && (
                                <GraphBloc dataKey='type_count' graph={InterventionsType} />
                            )}
                        </Bloc>
                        <Bloc
                            title="Nombre d'interventions"
                            icon='demography_page'
                            descr={
                                isReady ? undefined : 'Aucune données disponibles pour le moment.'
                            }
                        >
                            {isReady && (
                                <GraphBloc id='total' dataKey='total_count' graph={TotalThisYear} />
                            )}
                        </Bloc>
                    </div>

                    <div
                        className='main-content-1'
                        style={{ display: 'flex', height: 250, padding: '0 20px' }}
                    >
                        {/* <Bloc
              title="Provenances des interventions"
              icon="demography_page"
              descr={
                isReady
                  ? undefined
                  : "Aucune données disponibles pour le moment."
              }
            >
              {selects.Location?.length > 0 && (
                <GraphBloc dataKey="location_count" graph={Provenance} />
              )}
            </Bloc> */}
                        {isReady && mapData && (
                            <div className='map-wrap' style={{ width: '100%' }}>
                                <Map data={mapData} />
                            </div>
                        )}
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default HomePage
