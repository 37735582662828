import { Hint } from 'react-vis'
import { getObjectTotal } from './computation'

// TODO: fix la palette de couleurs (j'ai fait un mélange de CLB et des couleurs par défaut de react-vis)
export const colors = [
    '#6234cb',
    '#34cbad',
    '#cb3452',
    '#9dcb34',
    '#7D7F7D',
    '#BEBD7F',
    '#102C54',
    '#49678D',
    '#E55137',
    '#1C542D',
    '#75151E',
    '#45322E',
    '#E5BE01',
    '#2A6478',
    '#EC7C26',
    '#BC9CB0',
    '#F7C59F',
    '#7D1128',
    '#F95738',
    '#320E3B'
]

export const color = (i, offset = 0) => {
    if (i + offset >= colors.length) return '#555555'
    return colors[i + offset]
}

export function transformObjectData(data, xAxis, yAxis, additional = {}) {
    if (!data) return []
    const compute = (key, i) => {
        if (typeof additional === 'function') return additional(key, i)
        if (typeof additional === 'object') return additional
        return null
    }
    return Object.keys(data).map((key, i) => ({
        [xAxis]: key,
        [yAxis]: data[key] || 0,
        ...compute(key, i),
    }))
}
export const defaultLabel = 'N/A'

export const sortFunctionByValue = (a, b, asc = true) => {
    if (a.label == defaultLabel) return 1
    if (b.label == defaultLabel) return -1
    if (a.label == 'Autre' || a.label == 'Autres') return 1
    if (b.label == 'Autre' || b.label == 'Autres') return -1

    return asc ? a.value - b.value : b.value - a.value
}

export const renderHint = (hint, html) => (
    <Hint value={hint}>
        {html ? (
            html
        ) : (
            <div className='vis-hint'>
                <h4>
                    {hint.label} : {hint.value}
                </h4>
            </div>
        )}
    </Hint>
)

export const formatNo = no => parseFloat(no).toLocaleString('fr')

export const renderDiscrete = data => (
    <span>
        {data.label}
        <em>:</em>
        <b>{formatNo(data.value)}</b>
    </span>
)

export function flipToPerc(data, xAxis, yAxis, additional = {}, total = undefined) {
    try {
        total = total || getObjectTotal(data)
        return transformObjectData(data, xAxis, yAxis, additional).map(d => ({
            ...d,
            [yAxis]: Math.round((d[yAxis] / total) * 100),
        }))
    } catch (e) {
        return transformObjectData(data, xAxis, yAxis, additional)
    }
}
