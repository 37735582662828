import { createSlice } from '@reduxjs/toolkit'

export const FILTERS_SLICE_INIT = {}

export const FiltersSlice = createSlice({
    name: 'filters',
    initialState: FILTERS_SLICE_INIT,
    reducers: {
        set: (state, action) => {
            state.content = {
                ...action.payload,
            }
        },
        unset: state => {
            state.content = {}
        },
    },
})

export const { set, unset } = FiltersSlice.actions

export default FiltersSlice.reducer
