import { Link } from "react-router-dom";

function Logo(props) {
  const { link } = props;

  return (
    <Link
      to={(location) => (link ? "/admin/home" : location)}
      className={"logo" + (link ? " clickable" : "")}
    >
      <span>EMMIS</span>
      <span className="bg-dash">/</span>
    </Link>
  );
}

export default Logo;
