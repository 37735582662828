import Icon from "@material-ui/core/Icon";

const DICT = {
    circle: "fiber_manual_record",
    status: "wifi_tethering",
    menu: "menu",
    to: "keyboard_arrow_right",
    link: "link",
    back: "keyboard_arrow_left",
    dropdown: "keyboard_arrow_down",
    save: "save",
    close: "close",
    complete: "done",
    submit: "send",
    login: "login",
    logout: "logout",
    add: "add",
    edit: "edit",
    delete: "delete",
    see_more: "more_horiz",
    next: "keyboard_arrow_right",
    prev: "keyboard_arrow_left",
    up: "keyboard_arrow_up",
    down: "keyboard_arrow_down",
    download: "download",
    print: "print",
    user: "person",
    trad: "language",
    error: "error",
    loading: "rotate_left",
    mail: "send",
    phone: "phone",
    details: "visibility",
    fullscreen: "fullscreen",
    "!fullscreen": "fullscreen_exit",
    infos: "informations",
    filter: "filter_list",
    filter_date: "today",
    options: "tune",
    quote: "format_quote",
    upload: "upload",
    bookmark: "bookmark",
    home_page: "home",
    demography_page: "dashboard",
    problems_page: "extension",
    actions_page: "healing",
    references_page: "recent_actors",
    testimonies_page: "message",
    map_page: "room",
    management_page: "settings",
    log_page: "feed",
    reports_page: "get_app",
    intervention_page: "add_box",
    team: "group",
    participants: "assignment",
    insert_chart: "insert_chart",
    location: "map",
    demography: "group",
    problems: "warning",
    "chart-box": "chart-box",
    "account-circle": "account-circle",
    "map-marker-path": "map-marker-path",
};

const SDSIcon = ({ children, unsafe, ...props }) => {
    const type = String(children).toLowerCase();

    const computeIcon = () => {
        if (type in DICT) return DICT[type];
        if (unsafe) return type;
        return "remove_circle_outline";
    };

    return <Icon {...props}>{computeIcon()}</Icon>;
};

export default SDSIcon;
