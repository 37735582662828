import { useAbility } from '@casl/react'
import { Button, Form } from 'antd'
import { useEffect, useState } from 'react'
import { SelectService } from 'services'
import { AbilityContext, DeleteButtonWrap, Feedback } from 'utils'

// TODO : lock all fields if mode == 'default

export function useTableForm(params, texts, selectsList, dataFormatter, beforeSubmit) {
    const [selects, setSelects] = useState({})
    const ability = useAbility(AbilityContext)
    const [form] = Form.useForm()

    const { mode: tmpMode, actions, onClose, baseData } = params

    // Setup
    let mode = tmpMode
    if (mode === 'default' && ability.can(actions.edit?.ability.do, actions.edit?.ability.on))
        mode = 'edit'

    texts = texts[mode]

    // Fetching
    const fetchSelects = async () => {
        if ((selectsList || []).length === 0) return

        try {
            const response = await SelectService.list(selectsList)
            setSelects(response)
        } catch (e) {
            Feedback.Error('loading-selects', e)
        }
    }

    useEffect(() => {
        fetchSelects()
    }, [])

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue(dataFormatter ? dataFormatter(baseData) : baseData)
    }, [baseData?.id])

    // Actions
    const handleAction = async type => {
        if (['create', 'edit', 'del'].indexOf(type) === -1) return

        const { handler } = actions[type]
        const feedback = new Feedback.Async()
        if (!handler) return feedback.error()

        try {
            if (type === 'del') {
                await handler(baseData.id)
                feedback.success('Suppression effectuée avec succès')
                handleClose(true)
                return
            }

            const values = await form.validateFields()
            let data = beforeSubmit
                ? beforeSubmit({ ...baseData, ...values })
                : { ...baseData, ...values }
            await handler(data)
            feedback.success(
                type === 'create'
                    ? 'Création effectuée avec succès'
                    : 'Changements effectués avec succès'
            )
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
            handleClose(true)
        } catch (e) {
            if (e.errorFields)
                feedback.error('Attention, certains champs obligatoires ne furent pas remplis.')
            else feedback.error()
        }
    }

    const handleClose = (forceRefresh = false) => {
        // form.resetFields()
        onClose(forceRefresh)
    }

    // Defs
    const FormWrapper = ({ children: content }) => (
        <Form form={form} layout='vertical' onFinish={() => handleAction(mode)}>
            <h3 className='dark-title'>{texts.title}</h3>
            <div className='content-wrap'>{content}</div>
            <Footer />
        </Form>
    )

    const Footer = props => {
        if (mode === 'create')
            return (
                <div className='buttons'>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button className='blue' htmlType='submit'>
                        Créer
                    </Button>
                </div>
            )

        return (
            <div className='buttons'>
                <Button onClick={handleClose}>{mode === 'edit' ? 'Annuler' : 'Fermer'}</Button>
                {actions.del && (
                    <DeleteButtonWrap
                        do={actions.del.ability.do}
                        on={actions.del.ability.on}
                        confirm='Attention, cette action est irréversible. Voulez-vous vraiment continuer?'
                        ok='Effectuer la supression'
                        cancel='Annuler'
                        action={() => handleAction('del')}
                        deletable
                    >
                        <Button className='red'>Supprimer</Button>
                    </DeleteButtonWrap>
                )}
                {mode === 'edit' && (
                    <Button className='blue' htmlType='submit'>
                        Soumettre
                    </Button>
                )}
            </div>
        )
    }

    return {
        FormWrapper,
        mode,
        selects,
        form,
    }
}
