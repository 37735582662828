import { configureStore } from '@reduxjs/toolkit'

import UserReducer from 'slices/user.slice'
import FiltersReducer from 'slices/filters.slice'
import CounterReducer from 'slices/counter.slice'
import TableReducer from 'slices/table.slice'

const preloadedState = JSON.parse(localStorage.getItem('redux-state')) || {}

const store = configureStore({
    reducer: {
        user: UserReducer,
        filters: FiltersReducer,
        counter: CounterReducer,
        table: TableReducer,
    },
    preloadedState,
})

store.subscribe(() => {
    const state = store.getState()
    localStorage.setItem('redux-state', JSON.stringify(state))
})

export default store
