import { Button, Form, Input, Select, Upload } from "antd";
import { Icon } from "components";
import { useTableForm } from "hooks";
import {
    exportItemWithAvatar,
    formatItemWithAvatar,
    formDecorators,
    renderSelectOptions,
    rules,
    selectProps,
} from "utils";

const texts = {
    create: {
        title: "Nouveau partenaire",
    },
    default: {
        title: "Détails d'un partenaire",
    },
    edit: {
        title: "Édition d'un partenaire",
    },
};

function TablePartnerForm(props) {
    const { selects, FormWrapper } = useTableForm(
        props,
        texts,
        ["User", "PartnerType"],
        (data) =>
            formatItemWithAvatar({
                ...data,
                user_id: (data?.users || []).map((u) => u.id),
            }),
        (data) => {
            const d = { ...data };
            delete d.locations;
            delete d.users;
            return exportItemWithAvatar(d);
        }
    );
    return (
        <FormWrapper>
            <>
                <Form.Item label="Nom" name="label">
                    <Input />
                </Form.Item>
                <Form.Item label="URL" name="url" rules={[rules.url]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Type de partenaire" name="partner_type_id">
                    <Select {...selectProps()} maxTagCount={2}>
                        {renderSelectOptions(selects.PartnerType, {
                            label: (partner) => partner.label,
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Logo"
                    name="avatar"
                    {...formDecorators.upload}
                >
                    <Upload
                        name="logo_upload"
                        accept="image/*"
                        beforeUpload={() => false}
                        maxCount={1}
                    >
                        <Button>
                            <Icon>upload</Icon>
                            Téléverser un fichier
                        </Button>
                    </Upload>
                </Form.Item>
            </>
        </FormWrapper>
    );
}

export default TablePartnerForm;
