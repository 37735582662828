import React, { Component, Fragment } from 'react'

import { Header } from 'components'
import { withRouter } from 'react-router'

/**
 * Component : ErrorBoundary
 * ---
 * Handles the errors in the Portal.
 * Gives a last-second catching control to the app.
 * Prevent crashes for the user.
 */

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)

        // We want this page to hide when the user then navigates.
        const { history } = this.props
        history.listen((location, action) => {
            if (this.state.hasError) this.setState({ hasError: false })
        })

        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true })
    }

    render() {
        if (this.state.hasError) {
            return (
                <Fragment>
                    <div className='error-display'>
                        <Header title="Une erreur s'est produite" subtitle='Attention' />

                        <div className='error-content'>
                            <p>Veuillez contacter les administrateurs si celle-ci persiste.</p>
                        </div>
                    </div>
                </Fragment>
            )
        }

        return this.props.children
    }
}

export default withRouter(ErrorBoundary)
