import React, { Component } from "react";
import { Select } from "antd";
import { selectProps, renderSelectOptions } from "utils";

import { connect } from "react-redux";

/**
 * Component : TableFilters > DropdownFloatingFilter
 * ---
 * Component that renders a dropdown floating filter.
 * The options are given through the tableReducer (lookups attribute).
 * This is the most optimal to fetch through the API, because the instances
 * of this class are deleted/rendered everytime they leave/appear on the screen.
 * The main fetch is executed in Table.loadLookups().
 *
 * @prop    { String }  select  The model to fetch, associated to the SelectService
 * @prop    { Object }  optionsParams   The parameters to give to each Options of the Dropdown
 */

class DropdownFloatingFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: props.select,
      optionsParams: props.optionsParams || {},
      fixedOptions: props.options || [],
      currentValue: [],
    };

    this.valueChanged = this.valueChanged.bind(this);
    this.onParentModelChanged = this.onParentModelChanged.bind(this);
  }

  // Filter's value change.
  valueChanged(currentValue) {
    this.setState({ currentValue }, () => {
      let valueToUse =
        this.state.currentValue.length === 0
          ? null
          : JSON.stringify(this.state.currentValue);
      this.props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged("equals", valueToUse);
      });
    });
  }

  // Called when the table gives a new value to display here.
  onParentModelChanged(parentModel) {
    let currentValue = [];
    if (parentModel) {
      let value = JSON.parse(parentModel.filter);
      if (Array.isArray(value)) currentValue = value;
    }

    this.setState({ currentValue });
  }

  render() {
    let options = [];

    if (this.state.fixedOptions.length > 0) options = this.state.fixedOptions;
    else options = this.props.lookups[this.state.select] || [];
    if (typeof this.state.select === "object") options = this.state.select;
    const len = this.state.currentValue.length;

    return (
      <Select
        showArrow
        size="small"
        mode="multiple"
        maxTagPlaceholder={
          <>
            <span>{len || "0"}</span>
            <small>Sélectionné{len && len > 1 ? "s" : ""}</small>
          </>
        }
        maxTagCount={0}
        {...selectProps()}
        onChange={this.valueChanged}
        value={this.state.currentValue}
      >
        {renderSelectOptions(options, {
          ...this.state.optionsParams,
          filter: (option) => option,
        })}
      </Select>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lookups: state.table.lookups,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  DropdownFloatingFilter
);
