import { Checkbox, Form, Input } from 'antd'
import { useTableForm } from 'hooks'
import { rules } from 'utils'

const texts = {
    create: {
        title: 'Nouveau coin de rue',
    },
    default: {
        title: "Détails d'un coin de rue",
    },
    edit: {
        title: "Édition d'un coin de rue",
    },
}

function TableStreetCornerForm(props) {
    const { FormWrapper } = useTableForm(props, texts, [])

    return (
        <FormWrapper>
            <>
                <Form.Item label='Nom' name='label'>
                    <Input />
                </Form.Item>
                <Form.Item label='Longitude' name='lng' rules={[rules.required, rules.latlng]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Latitude' name='lat' rules={[rules.required, rules.latlng]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Archiver' name='is_archive' valuePropName='checked'>
                    <Checkbox />
                </Form.Item>
            </>
        </FormWrapper>
    )
}

export default TableStreetCornerForm
