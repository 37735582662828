import { Popover } from 'antd'
import { ExternalLink } from 'components'
import {
    TableLogForm,
    TablePartnerForm,
    TableUserForm,
    TableLocationForm,
    TableOrganisationForm,
    TableTestimonialForm,
} from 'components/TableForms'
import moment from 'moment'
import ReactDOMServer from 'react-dom/server'
import {
    LogService,
    UserService,
    PartnerService,
    LocationService,
    OrganisationService,
    TestimonialService,
} from 'services'
import { Formatter, history, rules } from 'utils'
import { ARRONDISSEMENTS, CALL_PRIORITY, OUINONNA, STATUS, TYPE_FORM } from './formvalues'
import { CREATE, DELETE, describe, EDIT, listify } from './misc'
import { StreetCornerService } from 'services/streetCorner.service'
import TableStreetCornerForm from 'components/TableForms/StreetCorner'

/**
 * Constants : Tables
 * ---
 * File that stores the params for all the different
 * instance of tables.
 * See /src/components/Table.js + Ag-Grid for more informations.
 *
 * INSTANCE { Object }
 * @prop    { Function }    fetcher     The service's function to fetch the data in that table
 * @prop    { Boolean }     quickSearch     Show the quickSearch input or no
 * @prop    { Int }         refreshDelay    The interval at which the table should refresh.
 * @prop    { Object }      defaultSortModel     Default SortModel for this table. See Ag-Grid for more informations on SortModel.
 * @prop    { Object }      defaultColDefs  Ag-Grid attribute
 * @prop    { Array }       columnDefs      Ag-Grid attribute (insert all the columns for this table, headerName will be translated)
 * @prop    { Function }    onRowClicked    Ag-Grid attribute
 * @prop    { Function }    getRowClass     Ag-Grid attribute
 * @prop    { Object }      possibleActions     List of the possible actions (edit, create, delete) + params (event, ability)
 *
 * WARNING : Sometimes the data read in the valueGetters/cellRenderers might be undefined (while fetching). Must plan in consequence.
 * WARNING : If the sort for a column passes through another DB Table, use columnDefs.comparator --> ${table_name}.${field}. See triggered_alarms
 */

export const tableRender = jsx => ReactDOMServer.renderToString(jsx)

// ----------------------------------
// -------------- LOGS --------------
// ----------------------------------
const logs = {
    fetcher: (params, user) => {
        return user?.infos?.rang === 1
            ? LogService.list({
                  order_by: 'date',
                  order_direction: 'desc',
                  ...params,
              })
            : LogService.list({
                  order_by: 'date',
                  order_direction: 'desc',
                  ...params,
                  user_id: user?.infos?.id,
              })
    },
    columnDefs: [
        {
            headerName: 'Date',
            field: 'date',
            valueGetter: ({ data }) => {
                return data?.date ? Formatter.Date(data?.date) : '-'
            },
            type: 'date',
        },
        {
            headerName: 'Remplis par',
            field: 'user_id',
            comparator: 'user_id',

            valueGetter: ({ data }) =>
                data?.user?.surname && data?.user?.name
                    ? [data?.user?.surname, data?.user?.name].join(' ')
                    : '-',
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'User',
            },
        },
        {
            headerName: 'Binôme',
            field: 'binome_id',
            comparator: 'binome_id',

            valueGetter: ({ data }) =>
                data?.binome?.surname && data?.binome?.name
                    ? [data?.binome?.surname, data?.binome?.name].join(' ')
                    : '-',
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'User',
            },
        },
        {
            headerName: 'Type',
            field: 'type_form',
            comparator: 'type_form',
            valueGetter: ({ data }) => {
                const label = data?.type_form
                    ? TYPE_FORM.find(select => select.id === data?.type_form).label
                    : '-'
                return label
            },
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: TYPE_FORM,
                optionsParams: { label: 'label' },
            },
        },
        {
            headerName: 'Provenance',
            field: 'partner_id',
            comparator: 'partner_id',
            valueGetter: ({ data }) => (data?.partner ? data?.partner.label : '-'),
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Partner',
            },
        },
        {
            headerName: "Heure d'appel",
            field: 'hour_call',
            valueGetter: ({ data }) =>
                data?.hour_call ? Formatter.Time(moment(data?.hour_call)) : '-',

            sortable: false,
            filter: false,
        },
        {
            headerName: "Priorité d'appel",
            field: 'call_priority',
            valueGetter: ({ data }) => {
                const label = data?.call_priority
                    ? CALL_PRIORITY.find(select => select.id === data?.call_priority).label
                    : '-'
                return label
            },
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: CALL_PRIORITY,
                optionsParams: { label: 'label' },
            },
        },
        {
            headerName: 'Interlocuteur',
            field: 'interlocuteur',

            valueGetter: ({ data }) => (data?.interlocuteur ? data?.interlocuteur : '-'),
        },
        {
            headerName: "Heure d'intervention (début)",
            field: 'hour_start',
            valueGetter: ({ data }) => {
                const label = data?.hour_start ? Formatter.Time(moment(data?.hour_start)) : '-'
                return label
            },

            sortable: false,
            filter: false,
        },
        {
            headerName: "Heure d'intervention (fin)",
            field: 'hour_end',
            valueGetter: ({ data }) => {
                const label = data?.hour_end ? Formatter.Time(moment(data?.hour_end)) : '-'
                return label
            },

            sortable: false,
            filter: false,
        },
        {
            headerName: 'Police requise',
            field: 'presence_police',
            valueGetter: ({ data }) => {
                const label = data?.presence_police
                    ? OUINONNA.find(select => select.id === data?.presence_police).label
                    : '-'
                return label
            },
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: OUINONNA,
                optionsParams: { label: 'label' },
            },
        },
        {
            headerName: 'Arrondissement',
            field: 'arrondissement_id',
            comparator: 'arrondissement_id',
            valueGetter: ({ data }) =>
                ARRONDISSEMENTS.find(
                    arrondissement => arrondissement.id === data?.arrondissement_id
                )
                    ? ARRONDISSEMENTS.find(
                          arrondissement => arrondissement.id === data?.arrondissement_id
                      ).label
                    : '-',
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: ARRONDISSEMENTS,
                optionsParams: { label: 'label' },
            },
        },
        {
            headerName: 'District',
            field: 'district_id',
            comparator: 'district_id',
            valueGetter: ({ data }) =>
                data?.arrondissement_id && data?.district_id
                    ? ARRONDISSEMENTS[data.arrondissement_id - 1].districts.find(
                          district => district.id === data.district_id
                      ).label
                    : '-',
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: ARRONDISSEMENTS.flatMap(a => a.districts),
                optionsParams: { label: 'label' },
            },
        },
        {
            headerName: 'Observation',
            field: 'action_observation',
            type: 'number',
            comparator: 'action_observation',
            valueGetter: ({ data }) => data?.action_observation,
        },
        {
            headerName: 'Prise de contact',
            field: 'action_contact',
            type: 'number',
            comparator: 'action_contact',
            valueGetter: ({ data }) => data?.action_contact,
        },
        {
            headerName: 'Prise de contact - Riverain',
            field: 'action_contact_riv',
            type: 'number',
            comparator: 'action_contact_riv',
            valueGetter: ({ data }) => data?.action_contact_riv,
        },
        {
            headerName: 'Médiation',
            field: 'action_mediation',
            type: 'number',
            comparator: 'action_mediation',
            valueGetter: ({ data }) => data?.action_mediation,
        },
        {
            headerName: 'Médiation - Riverain',
            field: 'action_mediation_riv',
            type: 'number',
            comparator: 'action_mediation_riv',
            valueGetter: ({ data }) => data?.action_mediation_riv,
        },
        {
            headerName: 'Réduction des méfaits',
            field: 'action_reduction_mefait',
            type: 'number',
            comparator: 'action_reduction_mefait',
            valueGetter: ({ data }) => data?.action_reduction_mefait,
        },
        {
            headerName: 'Gestion de crise',
            field: 'action_crise',
            type: 'number',
            comparator: 'action_crise',
            valueGetter: ({ data }) => data?.action_crise,
        },
        {
            headerName: 'Seringues ramassés',
            field: 'action_seringues',
            type: 'number',
            comparator: 'action_seringues',
            valueGetter: ({ data }) => data?.action_seringues,
        },
        {
            headerName: 'Distribution de titres STM',
            field: 'action_distribution_stm',
            type: 'number',
            comparator: 'action_distribution_stm',
            valueGetter: ({ data }) => data?.action_distribution_stm,
        },
        {
            headerName: 'Gestion des incivilités',
            field: 'action_incivilites',
            type: 'number',
            comparator: 'action_incivilites',
            valueGetter: ({ data }) => data?.action_incivilites,
        },
        {
            headerName: 'Premiers soins/naloxone',
            field: 'action_premier_soin',
            type: 'number',
            comparator: 'action_premier_soin',
            valueGetter: ({ data }) => data?.action_premier_soin,
        },
        {
            headerName: 'Intervention de soutien',
            field: 'action_soutien',
            type: 'number',
            comparator: 'action_soutien',
            valueGetter: ({ data }) => data?.action_soutien,
        },
        {
            headerName: 'Prise des signes vitaux',
            field: 'action_signes_vitaux',
            type: 'number',
            comparator: 'action_signes_vitaux',
            valueGetter: ({ data }) => data?.action_signes_vitaux,
        },
        {
            headerName: 'Gestion de plainte citoyenne',
            field: 'action_plainte_citoyenne',
            type: 'number',
            comparator: 'action_plainte_citoyenne',
            valueGetter: ({ data }) => data?.action_plainte_citoyenne,
        },
        {
            headerName: 'Gestion de plainte commerçant',
            field: 'action_plainte_commercant',
            type: 'number',
            comparator: 'action_plainte_commercant',
            valueGetter: ({ data }) => data?.action_plainte_commercant,
        },
        {
            headerName: 'Accompagnement',
            field: 'action_accompagnement',
            type: 'number',
            comparator: 'action_accompagnement',
            valueGetter: ({ data }) => data?.action_accompagnement,
        },
        {
            headerName: 'Distribution de besoins de base',
            field: 'action_besoin_base',
            type: 'number',
            comparator: 'action_besoin_base',
            valueGetter: ({ data }) => data?.action_besoin_base,
        },
        {
            headerName: 'Autre',
            field: 'action_autre',
            type: 'number',
            comparator: 'action_autre',
            valueGetter: ({ data }) => data?.action_autre,
        },
        {
            headerName: 'Sexe mâle',
            field: 'sex_male',
            type: 'number',
            valueGetter: ({ data }) => data?.sex_male,
        },
        {
            headerName: 'Sexe féminin',
            field: 'sex_female',
            type: 'number',
            valueGetter: ({ data }) => data?.sex_female,
        },
        {
            headerName: 'Transexuel',
            field: 'sex_trans',
            type: 'number',
            valueGetter: ({ data }) => data?.sex_trans,
        },
        {
            headerName: 'Anglais',
            field: 'langage_english',
            type: 'number',
            valueGetter: ({ data }) => data?.langage_english,
        },
        {
            headerName: 'Français',
            field: 'langage_french',
            type: 'number',
            valueGetter: ({ data }) => data?.langage_french,
        },
        {
            headerName: 'Autre',
            field: 'langage_other',
            type: 'number',
            valueGetter: ({ data }) => data?.langage_other,
        },
        {
            headerName: 'Caucasien',
            field: 'origin_caucas',
            type: 'number',
            valueGetter: ({ data }) => data?.origin_caucas,
        },
        {
            headerName: 'Minorité visible',
            field: 'origin_minority',
            type: 'number',
            valueGetter: ({ data }) => data?.origin_minority,
        },
        {
            headerName: 'Autochtone/Inuit',
            field: 'origin_autocht',
            type: 'number',
            valueGetter: ({ data }) => data?.origin_autocht,
        },
        {
            headerName: 'Moins de 18 ans',
            field: 'age_under18',
            type: 'number',
            valueGetter: ({ data }) => data?.age_under18,
        },
        {
            headerName: '18 - 24 ans',
            field: 'age_1824',
            type: 'number',
            valueGetter: ({ data }) => data?.age_1824,
        },
        {
            headerName: '25 - 39 ans',
            field: 'age_2539',
            type: 'number',
            valueGetter: ({ data }) => data?.age_2539,
        },
        {
            headerName: '40 - 54 ans',
            field: 'age_4054',
            type: 'number',
            valueGetter: ({ data }) => data?.age_4054,
        },
        {
            headerName: '55 - 64 ans',
            field: 'age_5564',
            type: 'number',
            valueGetter: ({ data }) => data?.age_5564,
        },
        {
            headerName: '65 ans et plus',
            field: 'age_65more',
            type: 'number',
            valueGetter: ({ data }) => data?.age_65more,
        },
        {
            headerName: 'Stabilité résidentielle',
            field: 'problem_stabilite_residentielle',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_stabilite_residentielle,
        },
        {
            headerName: 'Itinérance chronique',
            field: 'problem_itinerance_chronique',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_itinerance_chronique,
        },
        {
            headerName: 'Itinérance cyclique',
            field: 'problem_intinerance_cyclique',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_intinerance_cyclique,
        },
        {
            headerName: 'Itinérance situationnelle',
            field: 'problem_intinerance_situationelle',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_intinerance_situationelle,
        },
        {
            headerName: 'En appartement',
            field: 'problem_appartement',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_appartement,
        },
        {
            headerName: 'Toxicomanie',
            field: 'problem_toxicomanie',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_toxicomanie,
        },
        {
            headerName: 'Alcoolisme',
            field: 'problem_alcoolisme',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_alcoolisme,
        },
        {
            headerName: 'Jeu excessif',
            field: 'problem_jeu',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_jeu,
        },
        {
            headerName: 'Judiciaire',
            field: 'problem_judiciaire',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_judiciaire,
        },
        {
            headerName: 'Travail du sexe',
            field: 'problem_travail_sexe',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_travail_sexe,
        },
        {
            headerName: 'Santé Mentale',
            field: 'problem_sante_mentale',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_sante_mentale,
        },
        {
            headerName: 'Santé Physique',
            field: 'problem_sante_physique',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_sante_physique,
        },
        {
            headerName: 'Violence/intimidation acteur',
            field: 'problem_violence_acteur',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_violence_acteur,
        },
        {
            headerName: 'Violence/intimidation victime',
            field: 'problem_violence_victime',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_violence_victime,
        },
        {
            headerName: 'Troubles de comportement',
            field: 'problem_trouble_comportement',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_trouble_comportement,
        },
        {
            headerName: 'Fugue',
            field: 'problem_fugue',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_fugue,
        },
        {
            headerName: 'Nouvel arrivant/immigration',
            field: 'problem_immigration',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_immigration,
        },
        {
            headerName: 'Besoins de base',
            field: 'problem_besoin_base',
            type: 'number',
            valueGetter: ({ data }) => data?.problem_besoin_base,
        },
        {
            headerName: 'Status',
            field: 'status',
            valueGetter: ({ data }) =>
                STATUS.find(status => status.id === data?.status)
                    ? STATUS.find(status => status.id === data?.status).label
                    : '-',
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: STATUS,
                optionsParams: { label: 'label' },
            },
        },

        {
            headerName: 'Notes',
            field: 'note',
            valueGetter: ({ data }) => (data?.note ? data.note : '-'),
        },
        {
            headerName: 'Accompagnement (lieu)',
            field: 'accompagnement_partner_id',
            valueGetter: ({ data }) => {
                return data?.accompagnement_partner ? data?.accompagnement_partner?.label : '-'
            },
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Partner',
                optionsParams: { label: 'label' },
            },
        },
        {
            headerName: 'Référence (lieu)',
            field: 'partners',
            valueGetter: ({ data }) =>
                data?.partners ? data?.partners.map(partner => partner.label).join(', ') : '-',
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Partner',
            },
            sortable: false,
        },
    ],
    FormComponent: TableLogForm,
    onRowClicked: e => history.push(`/admin/logs/${e.data.id}`),
}

const users = {
    fetcher: params => UserService.list(params),
    columnDefs: [
        {
            headerName: 'Rang',
            field: 'rang',
            valueGetter: ({ data }) => describe('user-ranks', data?.rang)?.label || '',
            type: 'dropdown',
            floatingFilterComponentParams: {
                options: listify('user-ranks'),
            },
        },
        {
            headerName: 'Nom',
            field: 'name',
            valueGetter: ({ data }) => `${data?.surname || ''} ${data?.name || ''}`,
        },
        {
            headerName: 'Courriel',
            field: 'email',
        },
        {
            headerName: 'Téléphone',
            field: 'phone',
            valueGetter: ({ data }) =>
                `${data?.phone?.trim().replace(rules.phone.pattern, '($1) $2-$3') || ''}`,
        },
        {
            headerName: 'Poste',
            field: 'poste',
        },
    ],
    FormComponent: TableUserForm,
    possibleActions: {
        create: {
            label: 'Nouvel utilisateur',
            ability: { do: CREATE, on: 'User' },
            handler: UserService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'User' },
            handler: UserService.update,
        },
        del: {
            ability: { do: DELETE, on: 'User' },
            handler: UserService.del,
        },
    },
}

const partners = {
    fetcher: params => PartnerService.list(params),
    columnDefs: [
        {
            headerName: 'Nom',
            field: 'label',
        },
        {
            headerName: 'Logo',
            field: 'avatar_url',
            sortable: false,
            filter: false,
            floatingFilter: false,
            valueGetter: ({ data }) => data,
            cellRendererFramework: ({ value: data }) => (
                <Popover
                    overlayClassName='table-popover'
                    content={<img src={data?.avatar_url} alt={data?.label}></img>}
                >
                    <ExternalLink targetBlank label='Image' button>
                        {data?.avatar_url}
                    </ExternalLink>
                </Popover>
            ),
        },
        {
            headerName: 'URL',
            field: 'url',
            cellRendererFramework: ({ value }) => <ExternalLink targetBlank>{value}</ExternalLink>,
        },
        {
            headerName: 'Type de partenaire',
            field: 'partner_type_id',
            sortable: false,
            filter: false, // TODO: faire marcher le filtre sur cette colonne
            floatingFilter: false,
            valueGetter: ({ data }) => {
                return data?.partner_type?.label
            },
        },
    ],
    FormComponent: TablePartnerForm,
    possibleActions: {
        create: {
            label: 'Nouveau partenaire',
            ability: { do: CREATE, on: 'Partner' },
            handler: PartnerService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Partner' },
            handler: PartnerService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Partner' },
            handler: PartnerService.del,
        },
    },
}

const locations = {
    fetcher: params => LocationService.list(params),
    columnDefs: [
        {
            headerName: 'Nom',
            field: 'label',
        },
        {
            headerName: 'Adresse',
            field: 'address',
        },
    ],
    FormComponent: TableLocationForm,
    possibleActions: {
        create: {
            label: 'Nouveau lieu',
            ability: { do: CREATE, on: 'Location' },
            handler: LocationService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Location' },
            handler: LocationService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Location' },
            handler: LocationService.del,
        },
    },
}

const organisations = {
    fetcher: params => OrganisationService.list(params),
    columnDefs: [
        {
            headerName: 'Catégorie',
            field: 'categorie_id',
            type: 'dropdown',
            valueGetter: ({ data }) => data?.category?.label,
            floatingFilterComponentParams: {
                select: 'OrganisationCategory',
            },
        },
        {
            headerName: 'Nom',
            field: 'name',
        },
        {
            headerName: 'URL',
            field: 'url',
            cellRendererFramework: ({ value }) => <ExternalLink targetBlank>{value}</ExternalLink>,
        },
    ],
    FormComponent: TableOrganisationForm,
    possibleActions: {
        create: {
            label: 'Nouvel organisme',
            ability: { do: CREATE, on: 'Organisation' },
            handler: OrganisationService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Organisation' },
            handler: OrganisationService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Organisation' },
            handler: OrganisationService.del,
        },
    },
}

const street_corner = {
    fetcher: params => StreetCornerService.list(params),
    columnDefs: [
        {
            headerName: 'Nom',
            field: 'label',
        },
        {
            headerName: 'Archiver',
            field: 'is_archive',
            type: 'bool',
            valueGetter: ({ data }) => (data?.is_archive ? 'Oui' : 'Non'),
        },
        {
            headerName: 'Latitude',
            field: 'lat',
            filter: null,
        },
        {
            headerName: 'Longitude',
            field: 'lng',
            filter: null,
        },
    ],
    FormComponent: TableStreetCornerForm,
    possibleActions: {
        create: {
            label: 'Nouveau coin de rue',
            ability: { do: CREATE, on: 'StreetCorner' },
            handler: StreetCornerService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'StreetCorner' },
            handler: StreetCornerService.update,
        },
        del: {
            ability: { do: DELETE, on: 'StreetCorner' },
            handler: StreetCornerService.del,
        },
    },
}

const testimonies = {
    fetcher: params => TestimonialService.list(params),
    columnDefs: [
        {
            headerName: 'Entreprise',
            field: 'corporation',
        },
        {
            headerName: 'Fonction',
            field: 'function',
        },
        {
            headerName: 'Nom',
            field: 'name',
        },
        {
            headerName: 'Témoignage',
            field: 'text',
        },
    ],
    FormComponent: TableTestimonialForm,
    possibleActions: {
        create: {
            label: 'Nouveau témoignage',
            ability: { do: CREATE, on: 'Testimonial' },
            handler: TestimonialService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Testimonial' },
            handler: TestimonialService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Testimonial' },
            handler: TestimonialService.del,
        },
    },
}

export function tableManager(instance) {
    switch (instance) {
        case 'logs':
            return logs
        case 'logs-with-create':
            const hidden = [
                'date',
                'location_id',
                'user_name',
                'partner_id',
                'sexe_homme',
                'sexe_femme',
                'sexe_trans',
            ]
            return {
                ...logs,
                columnDefs: logs.columnDefs.map(c => {
                    if (hidden.indexOf(c.field) !== -1) c.hide = true
                    return c
                }),
                possibleActions: {
                    ...logs.possibleActions,
                    create: {
                        label: 'Ajouter une nouvelle entrée au journal de bord',
                        ability: { do: CREATE, on: 'Log' },
                        handler: LogService.create,
                    },
                },
                hideWhenEmpty: true,
            }
        case 'users':
            return users
        case 'partners':
            return partners
        case 'locations':
            return locations
        case 'organisations':
            return organisations
        case 'testimonies':
            return testimonies
        case 'street_corner':
            return street_corner

        // Catch
        default:
            return false
    }
}
