import { Form, Input, TimePicker } from 'antd'
import Search from 'components/Search'
import { fuzzySearch } from './Step1'
import { AGE, CALL_PRIORITY, LANGAGE, ORIGIN, OTHER_PARTNER, SEXE } from 'constants/formvalues'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { rules } from 'utils'

export const CustomTimePicker = props => {
    const { value, onChange, date, ...other } = props
    let time = value ? moment(value) : null

    const triggerChange = eTime => {
        if (eTime) {
            time = eTime.toLocaleString().slice(16, 24)
            onChange && onChange(date + ' ' + eTime.toLocaleString().slice(16, 24))
            return
        }
        onChange && onChange(null) // null not undefined for DB!
    }

    return (
        <TimePicker
            defaultValue={time}
            onSelect={triggerChange}
            onChange={triggerChange}
            showSecond={false}
            value={time}
            {...other}
        />
    )
}

function InterventionStep2(props) {
    const { intervention, selects, activeStep } = props
    const { Partner } = selects

    const [date, setDate] = useState(intervention?.date)
    const [heureAppel, setHeureAppel] = useState(moment(intervention?.hour_call))

    async function search(label, select) {
        return {
            data: fuzzySearch(select, label),
        }
    }

    const getSelect = name => {
        switch (name) {
            case 'sex':
                return SEXE.find(type => type.id === intervention.sex)
            case 'other_partner':
                return OTHER_PARTNER.find(type => type.id === intervention.other_partner)
            case 'call_priority':
                return CALL_PRIORITY.find(type => type.id === intervention.call_priority)
            case 'langage':
                return LANGAGE.find(type => type.id === intervention.langage)
            case 'origin':
                return ORIGIN.find(type => type.id === intervention.origin)
            case 'age':
                return AGE.find(type => type.id === intervention.age)
            default:
                break
        }
    }

    useEffect(() => {
        if (intervention.date) {
            setDate(intervention.date)
        }
        if (intervention.hour_call) {
            setHeureAppel(moment(intervention.hour_call))
        }
    }, [intervention])

    return (
        <div className='intervention-step-2'>
            <div className='inputs-wrap-1'>
                <Form.Item name='partner_id' label='Provenance'>
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={
                            Partner?.find(partner => partner.id === intervention?.partner_id) ||
                            null
                        }
                        fetcher={obj => search(obj.label, Partner)}
                    />
                </Form.Item>
                <Form.Item name='hour_call' label="Heure de l'appel">
                    <CustomTimePicker
                        className={date === undefined ? 'disabled-input' : ''}
                        value={heureAppel}
                        onChange={setHeureAppel}
                        date={date}
                        disabled={date === undefined}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </div>
            <div className='inputs-wrap-2'>
                <Form.Item name='interlocuteur' label='Interlocuteur'>
                    <Input value={intervention?.interlocuteur} />
                </Form.Item>
                <Form.Item name='call_priority' label="Priorité de l'appel">
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={getSelect('call_priority') || null}
                        fetcher={obj => search(obj.label, CALL_PRIORITY)}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

export default InterventionStep2
