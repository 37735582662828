import { Spin } from 'antd';
import { Header, Helmet } from 'components';
import { Bar, Donut } from 'components/Charts';
import { AGE, LANGAGE, ORIGIN, SEXE } from 'constants/formvalues';
import { useStats } from 'hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { transformObjectData } from 'utils';

const AgeGroup = (props) => {
    const { data } = props;
    let newData = {
        'Moins de 18 ans': 0,
        '18 - 24 ans': 0,
        '25 - 39 ans': 0,
        '40 - 54 ans': 0,
        '55 - 64 ans': 0,
        '65 ans et plus': 0,
    };
    Object.keys(data).forEach((key) => {
        newData[AGE.find((age) => age.name === key).label] += data[key] ?? 0;
    });
    return Object.values(newData).filter((value) => value > 0).length > 0 ? (
        <Bar
            height={200}
            data={transformObjectData(newData, 'x', 'y')}
            percentage
        />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    );
};

const SexeGraph = (props) => {
    const { data } = props;
    let newData = {};
    newData = Object.keys(data).map((key) => {
        return {
            label: SEXE.find((sex) => sex.name === key).label,
            value: data[key],
        };
    });
    const sum = Object.values(data).reduce((acc, val) => acc + val, 0);
    return sum > 0 ? (
        <Donut data={newData} height={200} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    );
};

const Language = (props) => {
    const { data } = props;
    let newData = {};
    newData = Object.keys(data).map((key) => {
        return {
            label: LANGAGE.find((origin) => origin.name === key).label,
            value: data[key],
        };
    });
    const sum = Object.values(data).reduce((acc, val) => acc + val, 0);
    return sum > 0 ? (
        <Donut data={newData} height={200} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    );
};

const OCGraph = (props) => {
    const { data } = props;
    let newData = {};
    newData = Object.keys(data).map((key) => {
        return {
            label: ORIGIN.find((origin) => origin.name === key).label,
            value: data[key],
        };
    });
    const sum = Object.values(data).reduce((acc, val) => acc + val, 0);
    return sum > 0 ? (
        <Donut data={newData} height={200} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    );
};

function ProfilPage() {
    // const { Filters, StatsWrapper, GraphBloc } = useStats('references')
    const { Filters, GraphBloc, StatsWrapper } = useStats('profils');
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        if (Filters && GraphBloc && StatsWrapper) {
            setIsReady(true);
        }
        return () => {
            setIsReady(false);
        };
    }, [Filters, GraphBloc, StatsWrapper]);

    return (
        <div className="profil-page">
            <Helmet title="Profil des personnes" />
            <Header
                title="Profil des personnes"
                subtitle="Tableau de bord"
                filters={Filters}
                showPrint
            />
            <Spin spinning={!isReady}>
                {isReady && (
                    <StatsWrapper>
                        <GraphBloc
                            title="Sexe"
                            dataKey="sexe"
                            graph={SexeGraph}
                        />
                        <GraphBloc
                            title="Origines culturelles"
                            dataKey="origin"
                            graph={OCGraph}
                        />
                        <GraphBloc
                            title="Langues parlées"
                            dataKey="langue"
                            graph={Language}
                        />
                        <GraphBloc
                            title="Tranches d'âges"
                            dataKey="age"
                            graph={AgeGroup}
                        />
                    </StatsWrapper>
                )}
            </Spin>
        </div>
    );
}

export default ProfilPage;
