import { Button, Form, Input, Select, Upload } from 'antd'
import { Icon } from 'components'
import { listify } from 'constants/misc'
import { useTableForm } from 'hooks'
import {
    formatItemWithAvatar,
    exportItemWithAvatar,
    formDecorators,
    renderSelectOptions,
    rules,
    selectProps,
    textareaProps,
} from 'utils'

const texts = {
    create: {
        title: 'Nouvel utilisateur',
    },
    default: {
        title: "Détails d'un utilisateur",
    },
    edit: {
        title: "Édition d'un utilisateur",
    },
}

function TableUserForm(props) {
    const { mode, selects, FormWrapper } = useTableForm(
        props,
        texts,
        ['Partner'],
        data =>
            formatItemWithAvatar({
                ...data,
                partner_id: (data?.partners || []).map(p => p.id),
                rang: data?.rang ? String(data.rang) : null,
            }),
        exportItemWithAvatar
    )

    const { restricted } = props

    return (
        <FormWrapper>
            <>
                <Form.Item label='Prénom' name='surname'>
                    <Input />
                </Form.Item>
                <Form.Item label='Nom' name='name'>
                    <Input />
                </Form.Item>
                <Form.Item label='Courriel' name='email' rules={[rules.required, rules.email]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Téléphone' name='phone' rules={[rules.phone]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Poste' name='poste'>
                    <Input />
                </Form.Item>
                {!restricted && (
                    <>
                        <Form.Item label='Rang' name='rang'>
                            <Select {...selectProps()}>
                                {renderSelectOptions(listify('user-ranks'))}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Partenaire' name='partner_ids'>
                            <Select
                                mode='multiple'
                                defaultValue={props?.baseData?.partners?.map(p => p.id)}
                                maxTagCount={2}
                            >
                                {renderSelectOptions(selects.Partner)}
                            </Select>
                        </Form.Item>
                    </>
                )}
                <Form.Item label='Biographie' name='bio'>
                    <Input.TextArea {...textareaProps()} />
                </Form.Item>
                <Form.Item
                    label={mode === 'create' ? 'Mot de passe' : 'Nouveau mot de passe'}
                    name='password'
                    rules={mode === 'create' ? [rules.required, rules.password] : [rules.password]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item label='Photo' name='avatar' {...formDecorators.upload}>
                    <Upload
                        name='photo_upload'
                        accept='image/*'
                        beforeUpload={() => false}
                        maxCount={1}
                    >
                        <Button>
                            <Icon>upload</Icon>
                            Téléverser un fichier
                        </Button>
                    </Upload>
                </Form.Item>
            </>
        </FormWrapper>
    )
}

export default TableUserForm
