import { Form, Input, InputNumber, Select } from "antd";
import { renderSelectOptions, rules, selectProps } from "utils";
import { fuzzySearch } from "./Step1";
import Search from "components/Search";
import { AGE, LANGAGE, ORIGIN, SEXE, STATUS } from "constants/formvalues";

function InterventionStep4(props) {
	const { intervention, selects, activeStep } = props;
	const { TextArea } = Input;

	async function search(label, select) {
		return {
			data: fuzzySearch(select, label),
		};
	}

	const { Location, User, Partner } = selects;
	const getSelect = (name) => {
		switch (name) {
			case "status":
				return STATUS.find((type) => type.id === intervention.status);
			default:
				break;
		}
	};
	return (
		<div className="intervention-step-6">
			<div className="inputs-wrap-1">
				<Form.Item
					rules={activeStep === 5 ? [rules.required] : null}
					name="status"
					label="Status">
					<Search
						controlType="select"
						query="label"
						baseObject={getSelect("status") || null}
						fetcher={(obj) => search(obj.label, STATUS)}
					/>
				</Form.Item>
				<Form.Item
					name="partners"
					label="Référence(lieu)"
					rules={[rules.maxLength]}>
					<Search
						mode="multiple"
						controlType="select"
						query="label"
						baseObject={
							Partner?.filter((partner) =>
								intervention?.partners?.includes(partner.id)
							) || null
						}
						fetcher={(obj) => search(obj.label, Partner)}
					/>
				</Form.Item>
				<Form.Item
					name="accompagnement_partner_id"
					label="Accompagnement(lieu)">
					<Search
						controlType="select"
						query="label"
						baseObject={
							Partner?.find(
								(loc) => loc.id === intervention?.accompagnement_partner_id
							) || null
						}
						fetcher={(obj) => search(obj.label, Partner)}
					/>
					{/* <Input value={intervention?.accompagnement_location} /> */}
				</Form.Item>
			</div>
			<div className="inputs-wrap-2">
				<Form.Item
					rules={activeStep === 5 ? [rules.required] : null}
					name="note"
					label="Notes">
					<TextArea className="text-area" value={intervention?.note || null} />
				</Form.Item>
			</div>
		</div>
	);
}

export default InterventionStep4;
