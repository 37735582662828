import { createContext } from 'react'
import { createContextualCan } from '@casl/react'

/**
 * Utils : Abilities
 * ---
 * Creates the AbilityContext + Can component.
 * @example     "<Can do='edit' on='Alarm'><Button>Edit</Button></Can>"
 */

export const AbilityContext = createContext()
export const Can = createContextualCan(AbilityContext.Consumer)
