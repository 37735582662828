import { Form, Input, InputNumber, Select } from "antd";
import { renderSelectOptions, rules, selectProps } from "utils";
import { fuzzySearch } from "./Step1";
import Search from "components/Search";
import {
	AGE,
	LANGAGE,
	ORIGIN,
	PROBLEM,
	SEXE,
	STATUS,
} from "constants/formvalues";

function InterventionStep5(props) {
	const { intervention, selects, activeStep } = props;
	const { TextArea } = Input;

	async function search(label, select) {
		return {
			data: fuzzySearch(select, label),
		};
	}

	const { Location, User } = selects;
	const getSelect = (name) => {
		switch (name) {
			case "status":
				return STATUS.find((type) => type.id === intervention.status);
			default:
				break;
		}
	};

	return (
		<div className="intervention-step-5">
			<div className="inputs-wrap-1">
				<div className="actions-container">
					{PROBLEM.map((prob) => {
						return (
							<Form.Item
								name={prob.name}
								label={prob.label}
								key={prob.id}
								rules={activeStep === 4 ? [rules.required] : null}>
								<InputNumber min={0} key={prob.id} />
							</Form.Item>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default InterventionStep5;
