import { useState } from 'react'
import { DiscreteColorLegend, RadialChart } from 'react-vis'
import ReactResizeDetector from 'react-resize-detector'
import { isPrint, getObjectTotal, color } from 'utils'
import { useLocation } from 'react-router-dom'

function Donut({ height, data, total, percentage, title }) {
    const printMode = isPrint(useLocation())
    const [hint, setHint] = useState(false)

    total = total || getObjectTotal(data)
    data = data
        .sort((a, b) => b.value - a.value)
        .map((d, i) => ({
            ...d,
            perc: Math.round((d.value / total) * 100) || 0,
            color: color(i),
            className: hint ? (d.label === hint.label ? 'hovered' : 'not-hovered') : '',
            style: { opacity: !hint || d.label === hint.label ? 1 : 0.1 },
            radius: d.label === hint?.label ? 1.1 : 1,
        }))
    return (
        <div className='pie-chart-wrap'>
            <ReactResizeDetector handleWidth>
                {({ width }) => {
                    const diameter = (width > height ? height : width) - 30
                    return (
                        <div className='container'>
                            <RadialChart
                                colorType='literal'
                                animation={!printMode}
                                width={width || height}
                                height={height}
                                // innerRadius={diameter / 2 - 25}
                                radius={diameter / 2}
                                margin={{ left: 50, right: 50, top: 50 }}
                                getAngle={d => d.value}
                                data={data || []}
                                onValueMouseOver={v => setHint(v)}
                                onSeriesMouseOut={_ => setHint(false)}
                            />
                        </div>
                    )
                }}
            </ReactResizeDetector>

            {data.length > 0 && (
                <DiscreteColorLegend
                    items={(data || []).map((d, i) => ({
                        title: (
                            <>
                                <b>{percentage ? `${d.perc} %` : d.value}</b>
                                <em>:</em>
                                {d.label}
                            </>
                        ),
                        disabled: d.className === 'not-hovered',
                        color: color(i),
                        hint: { ...d },
                    }))}
                    onItemMouseEnter={item => setHint(item.hint)}
                    onItemMouseLeave={_ => setHint(false)}
                />
            )}
        </div>
    )
}

export default Donut
