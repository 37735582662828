import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from 'router'

import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from 'store'

import 'antd/dist/antd.css'
import 'assets/scss/styles.scss'

import fr_CA from 'antd/lib/locale-provider/fr_CA'

import moment from 'moment-timezone'
// import 'moment/locale/fr'
moment.locale('fr-ca')
moment.tz.setDefault('America/Montreal');

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={fr_CA}>
            <AppRouter />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
)
