import { createSlice } from '@reduxjs/toolkit'

const USER_SLICE_INIT = {
    logged: false,
    infos: {},
}

export const UserSlice = createSlice({
    name: 'user',
    initialState: USER_SLICE_INIT,
    reducers: {
        login: (state, action) => {
            state.logged = true
            state.infos = action.payload
        },
        logout: state => {
            state.logged = false
            state.infos = {}
        },
        update: (state, action) => {
            state.infos = action.payload
        },
    },
})

export const { login, logout, update } = UserSlice.actions

export default UserSlice.reducer
