import moment from 'moment'
import { subject } from '@casl/ability'
import { ABILITIES } from 'constants/abilities'
import { DATE_FORMAT, DATE_FORMAT_API } from 'constants/app'
import { FILTER } from 'constants/misc'
import { getDateRange } from './dates'
import { Formatter } from './format'
import { ARRONDISSEMENTS, TYPE_FORM, getDistricts } from 'constants/formvalues'

const pages = {
    profils: [
        {
            key: 'partner_id',
            subject: 'Partner',
            label: 'Partenaire',
            selectBack: true,
        },
        { key: 'binome_id', subject: 'User', label: 'Binôme', selectBack: true },
        {
            key: 'arrondissement_id',
            subject: 'Arrondissement',
            label: 'Arrondissement',
            selectBack: false,
            list: ARRONDISSEMENTS,
        },
        {
            key: 'district_id',
            subject: 'District',
            label: 'District',
            selectBack: false,
            list: getDistricts(),
        },
        {
            key: 'street_corner_id',
            subject: 'StreetCorner',
            label: 'Coin de rue',
            selectBack: true,
        },
        {
            key: 'type_form',
            subject: 'TypeForm',
            list: TYPE_FORM.sort((a, b) =>
                b.label.toUpperCase() < a.label.toUpperCase() ? 1 : -1
            ),
            label: 'Type',
            selectBack: false,
        },
    ],
    datas: [
        {
            key: 'partner_id',
            subject: 'Partner',
            label: 'Partenaire',
            selectBack: true,
        },
        { key: 'binome_id', subject: 'User', label: 'Binôme', selectBack: true },
        {
            key: 'arrondissement_id',
            subject: 'Arrondissement',
            label: 'Arrondissement',
            selectBack: false,
            list: ARRONDISSEMENTS,
        },
        {
            key: 'district_id',
            subject: 'District',
            label: 'District',
            selectBack: false,
            list: getDistricts(),
        },
        {
            key: 'street_corner_id',
            subject: 'StreetCorner',
            label: 'Coin de rue',
            selectBack: true,
        },
        {
            key: 'type_form',
            subject: 'TypeForm',
            list: TYPE_FORM.sort((a, b) =>
                b.label.toUpperCase() < a.label.toUpperCase() ? 1 : -1
            ),
            label: 'Type',
            selectBack: false,
        },
    ],
}

const defaultDatePreset = 'current-year'

export function getDefaultDateRange() {
    return {
        date: getDateRange(defaultDatePreset),
        datePreset: defaultDatePreset,
    }
}

export function filtersForPage(page) {
    if (!(page in pages)) return []

    // La fonction `subject` permet d'appeler un `can()` sur le filtre : ABILITIES.can(FILTER, filters[0])
    return pages[page].map(filter => subject(filter.subject, filter))
}

export function onlyAbleToFilter(filters) {
    return filters.filter(filter => ABILITIES().can(FILTER, filter))
}

export function formatFilters(filters, dest = 'display') {
    const tmp = { ...filters }
    let dates = tmp.date
    if (dates && Array.isArray(dates)) {
        if (dest === 'storage') tmp.date = dates.map(d => Formatter.Date(d))
        else if (dest === 'display') tmp.date = dates.map(d => moment(d, DATE_FORMAT))
        else if (dest === 'api') tmp.date = dates.map(d => Formatter.Date(d, DATE_FORMAT_API))
    }

    return tmp
}

export function parseFilters({ pathname, search }) {
    if (search === '') return null
    if (search.indexOf('?') === 0) search = search.substring(1)

    const [, page] = pathname.match(/(?:\/\w+)*\/(\w+)/)
    const filters = onlyAbleToFilter(filtersForPage(page))
    const allowed = [...filters.map(filter => filter.key), 'date', 'datePreset']
    return deserialize(search, allowed)
}

export function deserialize(search, keep) {
    const obj = {}
    const str = search.split('&')
    const add = key => keep === undefined || keep.includes(key)

    for (const arg of str) {
        const [key, value] = arg.split('=')
        const arrayKeyMatch = key.match(/(.+)\[\]$/)
        if (arrayKeyMatch) {
            const [, arrayKey] = arrayKeyMatch
            add(arrayKey) && (obj[arrayKey] = obj[arrayKey] ? [...obj[arrayKey], value] : [value])
        } else {
            add(key) && (obj[key] = value)
        }
    }

    return Object.keys(obj).length > 0 && obj
}
