import React, { Component } from 'react'
import moment from 'moment'
import { DatePicker } from 'antd'
import { datePickerProps } from 'utils'

/**
 * Component : TableFilters > DateFloatingFilter
 * ---
 * Component that renders a date floating filter.
 * @prop    { Function }    disabledDate    AntDesign prop to disable certain dates
 */

class DateFloatingFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentValue: [null, null],
            disabledDate: props.disabledDate || null,
        }

        this.valueChanged = this.valueChanged.bind(this)
        this.onParentModelChanged = this.onParentModelChanged.bind(this)
    }

    // Filter's value change.
    valueChanged(dates) {
        if (Array.isArray(dates) && dates.length === 2 && dates[0].diff(dates[1], 'minutes') === 0)
            dates[1].add(1, 'day')

        if (!dates) dates = []

        this.setState({ currentValue: dates }, () => {
            const dateStrings = (dates || []).map(date => date.format('YYYY-MM-DD'))
            let valueToUse = dates.length === 0 ? null : JSON.stringify(dateStrings)
            this.props.parentFilterInstance(instance =>
                instance.onFloatingFilterChanged('equals', valueToUse)
            )
        })
    }

    // Called when the table gives a new value to display here.
    onParentModelChanged(parentModel) {
        let currentValue = [null, null]
        if (parentModel) {
            let value = JSON.parse(parentModel.filter)
            if (Array.isArray(value)) {
                currentValue = value
                currentValue[0] = currentValue[0] ? moment(currentValue[0]) : null
                currentValue[1] = currentValue[1] ? moment(currentValue[1]) : null
            }
        }

        this.setState({ currentValue })
    }

    render() {
        return (
            <DatePicker.RangePicker
                size='small'
                {...datePickerProps()}
                value={this.state.currentValue}
                onChange={this.valueChanged}
                disabledDate={this.state.disabledDate}
            />
        )
    }
}

export default DateFloatingFilter
