import logoCanada from "assets/images/logo_canada.png";
import logoSDS from "assets/images/logo_sds.png";
import ExternalLink from "components/ExternalLink";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="page-footer">
      <div className="upper-footer">
        <div className="images">
          <img src={logoSDS} alt="Logo SDS" />
          <img
            src="https://upload.wikimedia.org/wikipedia/fr/9/9c/Logo_Montr%C3%A9al.svg"
            alt="Logo Montreal"
          />
        </div>
        <small>
          Ce projet est financé par la Ville de Montréal en partenariat avec la
          Société de développement social.
        </small>
        <div className="links">
          <Link to="/about">À propos</Link>
        </div>
      </div>
      <div className="lower-footer">
        <small>©2022 Action Médiation</small>
        <small>
          Réalisé par{" "}
          <ExternalLink targetBlank label="04h11">
            https://04h11.com/
          </ExternalLink>
        </small>
      </div>
    </footer>
  );
}

export default Footer;
