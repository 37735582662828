/**
 * Service : CustomBlocks
 * ---
 * Interraction with the DB to manipulate custom blocks.
 */

import { header, queryAPI, serialize } from 'utils'

export const CustomBlockService = {
    list,
    get,
    update,
    create,
    del,
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`custom_blocks?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`custom_blocks/${id}`, params)
}

async function update(block) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify(block),
    }

    return await queryAPI(`custom_blocks/${block.id}`, params, true)
}

async function create(block) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            block: block,
        }),
    }

    return await queryAPI(`custom_blocks`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`custom_blocks/${id}`, params, true)
}
