import { Select, AutoComplete, Popconfirm, Tooltip, message } from "antd";
import { Can } from "utils";
import { READ, EDIT, CREATE } from "constants/misc";

/**
 * Utils : Forms
 * ---
 * Functions to help the handling of forms.
 */

/**
 * Tells if a value is "checked" (=== true), because values can be formatted differently by the API.
 */
export const isChecked = (val) =>
	val === true || val === "true" || val === "1" || val === 1 || val === "t";

export const Feedback = {
	Async: class AsyncFeedback {
		constructor(loadingText = "Éxécution en cours...") {
			this.key = Date.now();
			message.loading({ content: loadingText, key: this.key });
		}

		success(text = "L'action a été complétée avec succès !") {
			message.success({ content: text, key: this.key });
		}

		error(text = "Une erreur est survenue...") {
			message.error({ content: text, key: this.key });
		}
	},
	Info: (text) => {
		if (!text) return;
		message.info(text);
	},
	Warn: (text) => {
		if (!text) return;
		message.warn(text);
	},
	Error: (type, e = null) => {
		if (e) console.error(e);

		if (e.status === 401) {
			message.error("Vous avez été déconnecté");
			return;
		}

		switch (type) {
			case "loading-page":
				message.error("La page n'a pas pu être chargée correctement");
				break;
			case "loading-selects":
				message.error("Certaines données n'ont pas pu être chargées");
				break;
			case "loading-home-stats":
				message.error(
					"Certaines données de cette page n'ont pas pu être chargés"
				);
				break;
			case "loading-stats":
				message.error(
					"Certains graphiques de cette page n'ont pas pu être chargés"
				);
				break;
			case "loading-intervention":
				message.error("L'intervention demandée ne semble pas exister...");
				break;
			default:
				message.error("Une erreur est survenue...");
				break;
		}
	},
};

/**
 * Renders an input depending on the given mode.
 * @param    { String }  mode
 * @param    { Object }  params     The 3 (or less) possible inputs, for the 3 given modes.
                                    The object should be formatted as so : { index : <>, edit: <>, create: <> }.
                                    If no "create" given, "edit" will be used (if no "edit", "index").
                                    "index" must be given.
 */
export const renderInput = (mode, params) => {
	const modes = [READ, EDIT, CREATE];
	for (let i = modes.indexOf(mode); i >= 0; i--) {
		if (modes[i] in params) return params[modes[i]];
	}

	return <span>-</span>;
};

/**
 * Renders the Options inside a Select (dropdown).
 * @param    { Array }      options             List of options (String | Object).
 * @param    { Object }     params              Parameters for display.
 *
 * Parameters   { Object }
 * @param   { String | Function }   label       If Function given, will rely on this function to display the label.
                                                If String given AND options are Objects, will display options[i][label] as label.
 * @param   { String | Function }   value       If Function given, will rely on this function to set the value.
                                                If String given AND options are Objects, will use options[i][value] as value.
 * @param   { Function }            filter      Filter function for the options.
 *
 * NOTE : Label + Value function will be given the option at the current index in the list.
 */
export const renderSelectOptions = (options = [], params = {}) => {
	return makeSelectOptions(options, params).map((option, i) => (
		<Select.Option key={i} value={option.id}>
			{option.label}
		</Select.Option>
	));
};
export const renderAutocompleteOptions = (options = [], params = {}) => {
	return makeSelectOptions(options, params).map((option, i) => (
		<AutoComplete.Option key={i} value={option.id}>
			{option.label}
		</AutoComplete.Option>
	));
};
export function makeSelectOptions(options = [], params = {}) {
	const { label, value, filter } = params;
	const compute = (option, field) => {
		if (typeof field === "function") return field(option);
		if (typeof option === "string") return option;
		if (typeof field === "string") return option[field];
		return null;
	};

	if (filter && typeof filter === "function") {
		options = [...options].filter(filter);
	}

	options = [...options].map((option) => {
		const formatted = {};
		if (typeof option === "string") {
			// If option is a string, format label + id, then format it to an object.
			// We pass the label/value the original String.
			formatted.id = value ? compute(option, value) : option;
			formatted.label = label ? compute(option, label) : option;
		} else {
			// If option is an object, we just format it according to the params given.
			formatted.id = value ? compute(option, value) : option.id;
			formatted.label = label
				? compute(option, label)
				: option.label || `${option.surname} ${option.name}`;
		}
		// Validation.
		if (
			typeof formatted.id === "undefined" ||
			typeof formatted.label === "undefined"
		)
			throw new Error("Invalid option supplied for dropdown");

		return formatted;
	});
	return options;
}

/**
 * A set of rules for the form.
 * @param   { Object }  t   The translation manager
 */
export const rules = {
	required: { required: true, message: "Ce champ est obligatoire." },
	maxLength: {
		type: "array",
		max: 5,
		message: "Veuillez choisir au maximum 5 références.",
	},
	email: { type: "email", message: "Veuillez entrer un courriel valide." },
	password: {
		pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d-]{8,}$/,
		message:
			"Le mot de passe doit contenir 8 caractères, au moins une lettre et un nombre.",
	},
	confirm_password: ({ getFieldValue }) => ({
		validator(_, value) {
			if (!value || getFieldValue("password") === value) {
				return Promise.resolve();
			}
			return Promise.reject(new Error("Le mot de passe ne correspond pas"));
		},
	}),
	latlng: {
		pattern: /^(-?[0-9]{2}\.[0-9]{4})$/,
		message: "Veuillez entrer une localisation valide. Ex.: 00.0000",
	},
	url: {
		pattern:
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
		message: "Veuillez entrer une URL valide.",
	},
	phone: {
		pattern: /^[+]?[(]?([0-9]{3})[)]?[-\s.]?([0-9]{3})[-\s.]?([0-9]{4,6})$/im,
		message:
			"Veuillez entrer un numéro de téléphone valide. Ex.: (123) 123-1234",
	},
	hex: {
		pattern: /^#([a-zA-Z0-9]{3,6})$/,
		message: "Veuillez entrer une valeur hexadécimal valide. Ex.: #000000",
	},
	postal_code: {
		pattern:
			/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}$/,
		message: "Veuillez entrer un code postal valide. Ex.: A1A 1A1",
	},
	ip: {
		pattern:
			/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
		message: "Veuillez entrer une adresse IP valide. Ex.: X.X.X.X",
	},
};

/**
 * A set of decorators for different form inputs.
 * Must give one of these decorator to the appropriate input.
 */
export const formDecorators = {
	checkbox: { valuePropName: "checked" },
	toggle: { valuePropName: "checked" },
	upload: {
		valuePropName: "fileList",
		// temporaire: https://github.com/ant-design/ant-design/issues/23487#issuecomment-618306898
		getValueFromEvent: (e) => {
			if (Array.isArray(e)) return e;
			return e && e.fileList;
		},
	},
};

/**
 * Checks if a mismatch of counts is presents in an
 * Intervention Form
 */
export function validateInterventionCounts(values, changes) {
	let mismatch = false,
		location = {};
	const should_match = ["sexe", "age", "frequence", "langue", "origine"];
	const should_be_below = [
		"alcoolisme",
		"besoin",
		"distribution_ticket",
		"fugue",
		"gestion_crise",
		"gestion_crise_riv",
		"gestion_incivilite",
		"gestion_plainte_riv",
		"intervention_soutien",
		"jeu",
		"judiciaire",
		"logement_appt",
		"logement_chronique",
		"logement_cyclique",
		"logement_situationnelle",
		"mediation",
		"mediation_riv",
		"nouvel_arrivant",
		"observation",
		"observation_riv",
		"premier_soin",
		"prise_contact",
		"prise_contact_riv",
		"reduction_mefaits",
		"reduction_mefaits_riv",
		"sante_mentale",
		"sante_physique",
		"seringue_ramasse",
		"toxicomanie",
		"travail",
		"trouble_comportement",
		"veteran",
		"violence",
		"violence_vict",
		"visite_riv",
	];

	if (changes) {
		// If given changes, check that change is worth the lookup
		const change_key = Object.keys(changes)[0];
		if (
			!should_be_below.includes(change_key) &&
			should_match.filter((key) => change_key.indexOf(key) === 0).length === 0
		)
			return [mismatch, location];
	}

	// Match
	const sums = {};
	let ref = 0;
	should_match.forEach((key) => {
		const keys = Object.keys(values).filter((k) => k.indexOf(key) === 0);

		const sum = keys.reduce((sum, k) => sum + parseFloat(values[k] || 0), 0);
		sums[key] = sum;
		if (sum > ref) ref = sum;
	});

	Object.keys(sums).forEach((key) => {
		const isDiff = sums[key] !== 0 && sums[key] < ref;
		if (isDiff) {
			location[key] = "diff";
			mismatch = true;
		}
	});

	// Below
	should_be_below.forEach((key) => {
		const isAbove = values[key] > ref;
		if (isAbove) {
			location[key] = "above";
			mismatch = true;
		}
	});

	return [mismatch, location];
}

// ---------------------------------------------------
// RENDER WRAPS
// ---------------------------------------------------
// Renderers to generate Wrap around Edit and Delete Buttons.
// Used primarly in HelperBar.

/**
 * Edit Button Wrap.
 * @param  { String }   do  Can.do
 * @param  { String }   on  Can.on
 */
export const renderEditButtonWrap = (params) => {
	return ({ children }) => {
		if (!params.do || !params.on) return children;

		return (
			<Can do={params.do} on={params.on}>
				{children}
			</Can>
		);
	};
};

/**
 * Delete Button Wrap.
 * @param  { String }   do  Can.do
 * @param  { String }   on  Can.on
 * @param  { Boolean }  deletable   Item is ready to be deleted
 * @param  { String }   confirm     Confirmation message
 * @param  { String }   ok          Ok message
 * @param  { String }   abort       Abort message
 * @param  { String }   tootlip     Tooltip message (if not deletable)
 */
export const DeleteButtonWrap = (props) => (
	<Can do={props.do} on={props.on}>
		{props.deletable ? (
			<Popconfirm
				placement="bottomLeft"
				title={props.confirm}
				okText={props.ok}
				okButtonProps={{ className: "red" }}
				cancelText={props.cancel}
				onConfirm={props.action}>
				{props.children}
			</Popconfirm>
		) : (
			<Tooltip placement="bottom" title={props.tooltip || ""}>
				{props.children}
			</Tooltip>
		)}
	</Can>
);
