import { useRef, useState } from "react";
import {
  FlexibleXYPlot,
  LabelSeries,
  VerticalBarSeries,
  XAxis,
} from "react-vis";
import ReactResizeDetector from "react-resize-detector";
import { getObjectTotal } from "utils";

function checkSideCollision(a, b, collisionMargin = 0) {
  return !(
    a.right + collisionMargin < b.left || a.left + collisionMargin > b.right
  );
}

function withPositionning(Wrapped, parent, collisionMargin) {
  let updateTicks = () => {};

  const renderTicks = () => {
    const container = parent.current?.container;
    if (!container) return <Wrapped />;

    const ticks = [
      ...container.querySelectorAll(".rv-xy-plot__axis--horizontal text"),
    ];
    if (ticks.length === 0) return <Wrapped />;

    updateTicks = () => {
      setTimeout(() => {
        const collide = ticks.some((_, i) => {
          if (i === 0) return false;
          return checkSideCollision(
            ticks[i - 1].getBoundingClientRect(),
            ticks[i].getBoundingClientRect(),
            collisionMargin
          );
        });

        ticks.forEach((tick, i) => {
          if (i % 2 === 0) {
            tick.setAttribute(
              "transform",
              collide ? "translate(0, 30)" : "translate(0, 14)"
            );
          }
        });
      }, 100);
    };

    updateTicks();
    return <Wrapped />;
  };

  return { updateTicks, renderTicks };
}

function Bar({ data, height, total, percentage, barWidth }) {
  const plot = useRef();
  const [hint, setHint] = useState(false);
  total = total || getObjectTotal(data, "y");
  data = data.map((d) => ({
    ...d,
    perc: Math.round((d.y / total) * 100) || 0,
    opacity: !hint || d.x === hint.x ? 1 : 0.1,
    color: "#34cbad",
  }));
  const { updateTicks, renderTicks } = withPositionning(XAxis, plot, 5);
  return (
    <ReactResizeDetector handleWidth onResize={updateTicks}>
      {({ width }) => (
        <FlexibleXYPlot
          ref={plot}
          xType="ordinal"
          width={width || 1000}
          height={height}
          margin={{ left: 20, right: 20 }}
        >
          {renderTicks()}
          <VerticalBarSeries
            colorType="literal"
            data={data}
            onValueMouseOver={(v) => setHint(v)}
            onSeriesMouseOut={(_) => setHint(false)}
          />
          <LabelSeries
            data={data}
            getLabel={(d) => (percentage ? `${d.perc} %` : d.y)}
          />
        </FlexibleXYPlot>
      )}
    </ReactResizeDetector>
  );
}

export default Bar;
