import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { history, Can } from "utils";
import { READ, EDIT, MANAGE } from "constants/misc";

import {
  E404Page,
  HomePage,
  AboutPage,
  InterventionPage,
  ManagementPage,
  LogPage,
  MapPage,
} from "pages";
import { ErrorBoundary, Nav, Footer } from "components";
import { AppContext } from "context";
import ProfilPage from "pages/Admin/Profil";
import InterventionsData from "pages/Admin/InterventionsData";
import LogIn from "pages/LogIn";
import { useSelector } from "react-redux";
import InterventionSummaryPage from "pages/Admin/InterventionSummary";

// Wrapper de Route qui ajoute la gestion d'accès à une page
function AppRoute({
  component: Component,
  componentProps,
  abilities,
  user,
  ...routeProps
}) {
  return (
    <Route
      {...routeProps}
      render={(matchProps) => {
        const Rendered = <Component {...componentProps} {...matchProps} />;
        if (!user.logged) return <Redirect to="/" />;
        if (!abilities) return Rendered;

        return (
          <Can
            do={abilities.do}
            on={
              typeof abilities.on === "function"
                ? abilities.on(matchProps)
                : abilities.on
            }
            passThrough
          >
            {(can) => (can ? Rendered : <E404Page />)}
          </Can>
        );
      }}
    />
  );
}

const routes = [
  {
    component: HomePage,
    path: "/admin/home",
  },
  {
    component: ProfilPage,
    path: "/stats/profil",
  },

  {
    component: InterventionsData,
    path: "/stats/donnees",
  },
  {
    component: MapPage,
    path: "/stats/lieux",
  },
  {
    component: InterventionPage,
    path: "/interventions/new",
    abilities: {
      do: READ,
      on: "Intervention",
    },
  },
  {
    component: InterventionSummaryPage,
    path: "/admin/logs/:id",
    abilities: {
      do: EDIT,
      on: "Intervention",
    },
  },
  {
    component: LogPage,
    path: "/admin/logs",
    abilities: {
      do: READ,
      on: "Intervention",
    },
  },
  {
    component: InterventionPage,
    path: "/interventions/:id/:step",
    abilities: {
      do: EDIT,
      on: "Intervention",
    },
  },
  {
    component: InterventionPage,
    path: "/interventions/:id",
    abilities: {
      do: EDIT,
      on: "Intervention",
    },
  },
  {
    component: ManagementPage,
    path: "/admin/management/:option",
    abilities: {
      do: MANAGE,
      on: "Portal",
    },
  },
  {
    component: ManagementPage,
    path: "/admin/management",
    abilities: {
      do: MANAGE,
      on: "Portal",
    },
  },
  {
    component: AboutPage,
    path: "/about",
  },
];

function AppRouter() {
  const user = useSelector((state) => state.user);

  return (
    <div className="page-wrap">
      <Router history={history}>
        <AppContext>
          <Nav />
          <div className="page-content">
            <ErrorBoundary>
              <Switch>
                <Route exact path="/" component={LogIn} />
                {React.Children.toArray(
                  routes.map((r) => <AppRoute user={user} {...r} />)
                )}
                <Route path="*" component={E404Page} />
              </Switch>
            </ErrorBoundary>
            <Footer />
          </div>
        </AppContext>
      </Router>
    </div>
  );
}

export default AppRouter;
