import { Form, InputNumber } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { rules } from 'utils'
import { fuzzySearch } from './Step1'
import Search from 'components/Search'
import { AGGRESSIF, ARRONDISSEMENTS, DEMARCHE, OUINONNA } from 'constants/formvalues'
import { CustomTimePicker } from './Step2'
import Map from 'components/Map'
import moment from 'moment'
import { DATE_FORMAT_API } from 'constants/app'

function InterventionStep3(props) {
    const { intervention, activeStep, form, selects } = props
    const { StreetCorner } = selects
    const [date, setDate] = useState(intervention?.date)

    const [mapPoints, setMapPoints] = useState(intervention?.lng_lat || [])

    const [debutIntervention, setDebutIntervention] = useState(intervention?.hour_start)
    const [finIntervention, setFinIntervention] = useState(intervention?.hour_end)

    const [arrondissementId, setArrondissementId] = useState(intervention?.arrondissement_id)

    async function search(label, select) {
        return {
            data: fuzzySearch(select, label),
        }
    }

    useEffect(() => {
        setArrondissementId(intervention?.arrondissement_id)
    }, [intervention?.arrondissement_id])

    useEffect(() => {
        if (intervention.lng && intervention.lat) {
            sessionStorage.setItem('@lng', intervention?.lng)
            sessionStorage.setItem('@lat', intervention?.lat)
            setMapPoints([intervention?.lng, intervention?.lat])
        } else {
            sessionStorage.removeItem('@lng')
            sessionStorage.removeItem('@lat')
        }
    }, [intervention?.lng, intervention?.lat])

    useEffect(() => {
        setDate(moment(intervention?.date).format(DATE_FORMAT_API))
    }, [intervention?.date])

    const cachedMap = useMemo(() => {
        return <Map markable data={[]} savedLngLat={mapPoints} changeLngLat={setMapPoints} />
    }, [])

    const getSelect = (name, secondParam) => {
        switch (name) {
            case 'intoxique':
                return OUINONNA.find(type => type.id === intervention.intoxique)
            case 'antagoniste':
                return OUINONNA.find(type => type.id === intervention.antagoniste)
            case 'positif':
                return OUINONNA.find(type => type.id === intervention.positif)
            case 'aggressif':
                return AGGRESSIF.find(type => type.id === intervention.aggressif)
            case 'connu':
                return OUINONNA.find(type => type.id === intervention.connu)
            case 'infraction':
                return OUINONNA.find(type => type.id === intervention.infraction)
            case 'probleme_mental':
                return OUINONNA.find(type => type.id === intervention.probleme_mental)
            case 'probleme_conso':
                return OUINONNA.find(type => type.id === intervention.probleme_conso)
            case 'presence_police':
                return OUINONNA.find(type => type.id === intervention.presence_police)
            case 'presence_police_note':
                return OUINONNA.find(type => type.id === intervention.presence_police_note)
            case 'arrondissement_id':
                return ARRONDISSEMENTS.find(type => type.id === intervention.arrondissement_id)

            case 'district_id':
                return ARRONDISSEMENTS[secondParam - 1].districts.find(
                    type => type.id === intervention.district_id
                )
            default:
                break
        }
    }

    return (
        <div className='intervention-step-3'>
            <div className='inputs-wrap-1'>
                <div className='fields'>
                    <Form.Item
                        rules={activeStep === 2 ? [rules.required] : null}
                        name='hour_start'
                        label="Début de l'intervention"
                    >
                        <CustomTimePicker
                            date={date}
                            value={debutIntervention}
                            onChange={setDebutIntervention}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={activeStep === 2 ? [rules.required] : null}
                        name='hour_end'
                        label="Fin de l'intervention"
                    >
                        <CustomTimePicker
                            date={date}
                            value={finIntervention}
                            onChange={setFinIntervention}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={activeStep === 2 ? [rules.required] : null}
                        name='presence_police'
                        label='Police requise'
                    >
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={getSelect('presence_police') || null}
                            fetcher={obj => search(obj.label, OUINONNA)}
                        />
                    </Form.Item>
                </div>

                {cachedMap}
            </div>
            <div className='inputs-wrap-2'>
                <Form.Item
                    rules={activeStep === 2 ? [rules.required] : null}
                    name='arrondissement_id'
                    label='Arrondissement'
                >
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={getSelect('arrondissement_id') || null}
                        fetcher={obj => search(obj.label, ARRONDISSEMENTS)}
                        onSearchValueChange={e => (e ? setArrondissementId(e.id) : null)}
                    />
                </Form.Item>
                <Form.Item
                    rules={activeStep === 2 ? [rules.required] : null}
                    name='district_id'
                    label='District'
                >
                    <Search
                        disabled={!arrondissementId}
                        controlType='select'
                        query='label'
                        baseObject={
                            arrondissementId ? getSelect('district_id', arrondissementId) : null
                        }
                        fetcher={obj =>
                            search(obj.label, ARRONDISSEMENTS[arrondissementId - 1].districts)
                        }
                    />
                </Form.Item>
            </div>
            <Form.Item name='street_corner_id' label='Coin de rue'>
                <Search
                    controlType='select'
                    query='label'
                    baseObject={
                        StreetCorner?.find(sc => sc.id === intervention?.street_corner_id) || null
                    }
                    fetcher={obj => search(obj.label, StreetCorner)}
                />
            </Form.Item>
            <div className='inputs-wrap-3'>
                <h3>Démarches prises</h3>
                <div className='actions-container'>
                    {DEMARCHE.map(demarche => {
                        return (
                            <Form.Item
                                rules={activeStep === 2 ? [rules.required] : null}
                                name={demarche.name}
                                label={demarche.label}
                                key={demarche.id}
                            >
                                <InputNumber min={0} key={demarche.id} />
                            </Form.Item>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InterventionStep3
