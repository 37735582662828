/**
 * Service : Intervention
 * ---
 * Interaction with the DB to manipulate interventions.
 */

import { header, queryAPI, serialize } from "utils";

export const LogService = {
  list,
  get,
  update,
  create,
  del,
};

async function list(filters = {}) {
  const params = {
    method: "GET",
    headers: header(),
  };

  return await queryAPI(`forms?${serialize(filters)}`, params);
}

async function get(id) {
  const params = {
    method: "GET",
    headers: header(),
  };

  return await queryAPI(`forms/${id}`, params);
}

async function update(log) {
  const params = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header(),
    },

    body: JSON.stringify({
      log: log,
    }),
  };

  return await queryAPI(`forms/${log.id}`, params, true);
}

async function create(log) {
  const params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header(),
    },

    body: JSON.stringify({
      log: log,
    }),
  };

  return await queryAPI(`forms`, params, true);
}

async function del(id) {
  const params = {
    method: "DELETE",
    headers: header(),
  };

  return await queryAPI(`forms/${id}`, params, true);
}
