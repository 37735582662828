import { SECRET, EXPORT_API_URL } from "constants/export";
import MD5 from "crypto-js/md5";
import moment from "moment";
import { serialize } from "./api";
import { ls } from "./storage";
import Buffer from "buffer/";

export function isPrint({ search }) {
  return new URLSearchParams(search).get("print") === "1";
}

export function parseToken({ search }) {
  const params = new URLSearchParams(search);
  const token = params.get("token");
  return token && decodeURIComponent(token);
}

export function rankFromToken(token) {
  if (!token) return null;
  const parts = token.split(".");
  if (parts.length < 2) return null;
  const payload = parts[1];
  if (!payload) return null;
  const decrypted = Buffer.Buffer.from(payload, "base64");
  const obj = JSON.parse(decrypted);
  if (!("aud" in obj)) return null;
  if (!("exp" in obj)) return null;
  if (!("iat" in obj)) return null;
  if (!("jti" in obj)) return null;
  if (!("scp" in obj)) return null;
  if (!("sub" in obj)) return null;
  return obj["rng"];
}

export class SdsExporter {
  constructor(props) {
    this.setPath(props?.path);
    this.setDiv(props?.div);
    this.setFilters(props?.filters);
  }

  setPath(path) {
    this.path = path || "/admin/home";
  }

  setDiv(div) {
    this.div = encodeURIComponent(div || "#graph-print-target");
  }

  setFilters(filters) {
    this.filters = filters && serialize(filters);
  }

  computeURL() {
    const pass = MD5(SECRET + moment().format("YYYYMMDD"))
      .toString()
      .toUpperCase();

    const token = ls.get("auth-token");
    let url = encodeURIComponent(`${window.location.origin}/#${this.path}`);
    url += encodeURIComponent("?print=1");
    url += encodeURIComponent(`&token=${token}`);
    if (this.filters) url += encodeURIComponent(`&${this.filters}`);

    return `${EXPORT_API_URL}?pass=${pass}&url=${url}&div=${this.div}`;
  }

  export() {
    window.open(this.computeURL());
  }

  async toBase64() {
    return new Promise(async (res, rej) => {
      try {
        const data = await fetch(this.computeURL());
        const blob = await data.blob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onerror = (e) => rej(e);
        reader.onloadend = () => {
          const base64 = reader.result;
          res(base64);
        };
      } catch (e) {
        rej(e);
      }
    });
  }
}
