import { useEffect, useMemo, useState } from 'react'
import { DatePicker, Form, Input, InputNumber, Select, TimePicker } from 'antd'
import { listify } from 'constants/misc'
import { useTableForm } from 'hooks'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Formatter, renderSelectOptions, rules, selectProps, textareaProps } from 'utils'
import { LogService, UserService } from 'services'
import { Feedback } from 'utils'
import { USER_RANK_INTERVENANT } from 'constants/misc'
import moment from 'moment'
import Search from 'components/Search'
import { getSelect, search } from 'components/Intervention/Step1'
import {
    AGE,
    ARRONDISSEMENTS,
    CALL_PRIORITY,
    DEMARCHE,
    LANGAGE,
    ORIGIN,
    OUINONNA,
    PROBLEM,
    SEXE,
    STATUS,
    TYPE_FORM,
} from 'constants/formvalues'
import { DATE_FORMAT_API, DATE_FORMAT_TIME_API } from 'constants/app'
import TextArea from 'antd/lib/input/TextArea'
import Map from 'components/Map'

const texts = {
    default: {
        title: "Détails d'une entrée",
    },
    edit: {
        title: "Édition d'une entrée",
    },
    create: {
        title: "Ajout d'une entrée",
    },
}

function TableLogForm(props) {
    const [logs, setLogs] = useState([])
    const user = useSelector(state => state.user)
    const { id: form_id } = useParams()
    const [arrondissementId, setArrondissementId] = useState(undefined)
    const [mapPoints, setMapPoints] = useState([logs?.lng, logs?.lat] || [])

    const onLoadFormat = data => {
        return {
            ...data,
            status: Number(data?.status) || null,
            date: data.date ? moment(data.date) : null,
            hour_call: data.hour_call ? moment(data.hour_call) : null,
            hour_start: data.hour_start ? moment(data.hour_start) : null,
            hour_end: data.hour_end ? moment(data.hour_end) : null,
            // partners: data?.partners
        }
    }

    const beforeSubmitFormat = data => {
        const tmpData = form.getFieldsValue()
        return {
            ...tmpData,
            id: Number(form_id),
            date: tmpData.date ? Formatter.Date(tmpData.date, DATE_FORMAT_TIME_API) : null,
            hour_call: tmpData.hour_call ? tmpData.hour_call : null,
            hour_start: tmpData.hour_start ? tmpData.hour_start : null,
            hour_end: tmpData.hour_end ? tmpData.hour_end : null,
            lng: sessionStorage.getItem('@lng') ? sessionStorage.getItem('@lng') : null,
            lat: sessionStorage.getItem('@lat') ? sessionStorage.getItem('@lat') : null,
            partner_ids: tmpData.partners,
        }
    }

    const { selects, FormWrapper, form } = useTableForm(
        props,
        texts,
        ['User', 'Partner', 'StreetCorner'],
        onLoadFormat,
        beforeSubmitFormat
    )
    const fetchLogs = async () => {
        try {
            const response = await LogService.get(form_id)
            setArrondissementId(response.arrondissement_id)
            setLogs(response)
        } catch (e) {
            Feedback.Error('loading-users-logs-create', e)
        }
    }

    useEffect(() => {
        fetchLogs()
    }, [])

    useEffect(() => {
        if (logs.lng && logs.lat) {
            sessionStorage.setItem('@lng', logs?.lng)
            sessionStorage.setItem('@lat', logs?.lat)
            setMapPoints([logs?.lng, logs?.lat])
        } else {
            sessionStorage.removeItem('@lng')
            sessionStorage.removeItem('@lat')
        }
    }, [logs?.lng, logs?.lat])

    const cachedMap = useMemo(() => {
        return <Map markable data={[]} savedLngLat={mapPoints} changeLngLat={setMapPoints} />
    }, [])

    return (
        <FormWrapper>
            <>
                <div className='form-items-flex'>
                    <Form.Item name='date' label='Date' rules={[rules.required]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name='binome_id' label='Binôme' rules={[rules.required]}>
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={logs.binome}
                            fetcher={obj => search(obj.label, selects.User)}
                        />
                    </Form.Item>
                </div>
                <div className='form-items-flex'>
                    <Form.Item name='type_form' label='Type' rules={[rules.required]}>
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={getSelect('type_form', logs) || null}
                            fetcher={obj => search(obj.label, TYPE_FORM)}
                        />
                    </Form.Item>
                    <Form.Item name='partner_id' label='Provenance'>
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={
                                selects.Partner?.find(partner => partner.id === logs?.partner_id) ||
                                null
                            }
                            fetcher={obj => search(obj.label, selects.Partner)}
                        />
                    </Form.Item>
                </div>
                <div className='form-items-flex'>
                    <Form.Item name='hour_call' label="Heure de l'appel">
                        <TimePicker
                            onSelect={e =>
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    hour_call: e,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item name='interlocuteur' label='Interlocuteur'>
                        <Input value={logs?.interlocuteur} />
                    </Form.Item>
                </div>
                <div className='form-items-flex'>
                    <Form.Item name='call_priority' label="Priorité de l'appel">
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={getSelect('call_priority', logs) || null}
                            fetcher={obj => search(obj.label, CALL_PRIORITY)}
                        />
                    </Form.Item>
                    <Form.Item name='presence_police' label='Police requise'>
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={getSelect('presence_police', logs) || null}
                            fetcher={obj => search(obj.label, OUINONNA)}
                        />
                    </Form.Item>
                </div>
                <div className='form-items-flex'>
                    <Form.Item
                        name='hour_start'
                        label="Début de l'intervention"
                        rules={[rules.required]}
                    >
                        <TimePicker
                            onSelect={e =>
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    hour_start: e,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name='hour_end'
                        label="Fin de l'intervention"
                        rules={[rules.required]}
                    >
                        <TimePicker
                            onSelect={e =>
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    hour_end: e,
                                })
                            }
                        />
                    </Form.Item>
                </div>
                <div className='map-logs'>{cachedMap}</div>
                <div className='form-items-flex'>
                    <Form.Item
                        name='arrondissement_id'
                        label='Arrondissement'
                        rules={[rules.required]}
                    >
                        <Search
                            controlType='select'
                            query='label'
                            baseObject={getSelect('arrondissement_id', logs) || null}
                            fetcher={obj => search(obj.label, ARRONDISSEMENTS)}
                            // onSearchValueChange={e => (e ? setArrondissementId(Number(e.id)) : null)}
                        />
                    </Form.Item>
                    {logs.district_id && (
                        <Form.Item name='district_id' label='District' rules={[rules.required]}>
                            <Search
                                disabled={!arrondissementId}
                                controlType='select'
                                query='label'
                                baseObject={
                                    arrondissementId ? getSelect('district_id', logs) : null
                                }
                                fetcher={obj =>
                                    search(
                                        obj.label,
                                        ARRONDISSEMENTS[form.getFieldValue('arrondissement_id') - 1]
                                            .districts
                                    )
                                }
                            />
                        </Form.Item>
                    )}
                </div>
                <Form.Item name='street_corner_id' label='Coin de rue'>
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={
                            selects.StreetCorner?.find(sc => sc.id === logs?.street_corner_id) ||
                            null
                        }
                        fetcher={obj => search(obj.label, selects.StreetCorner)}
                    />
                </Form.Item>
                <h3 className='dark-title'>Démarches prises</h3>
                <div className='form-items-flex-wrap'>
                    {DEMARCHE.map(demarche => {
                        return (
                            <Form.Item
                                name={demarche.name}
                                label={demarche.label}
                                key={demarche.id}
                                rules={[rules.required]}
                            >
                                <InputNumber min={0} key={demarche.id} />
                            </Form.Item>
                        )
                    })}
                </div>
                <h3 className='dark-title'>Sexes</h3>
                <div className='form-items-flex-wrap'>
                    {SEXE.map(sexe => {
                        return (
                            <Form.Item
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                                rules={[rules.required]}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
                <h3 className='dark-title'>Langues parlées</h3>
                <div className='form-items-flex-wrap'>
                    {LANGAGE.map(sexe => {
                        return (
                            <Form.Item
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                                rules={[rules.required]}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
                <h3 className='dark-title'>Origines Culturelles</h3>
                <div className='form-items-flex-wrap'>
                    {ORIGIN.map(sexe => {
                        return (
                            <Form.Item
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                                rules={[rules.required]}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
                <h3 className='dark-title'>Àges</h3>
                <div className='form-items-flex-wrap'>
                    {AGE.map(sexe => {
                        return (
                            <Form.Item
                                name={sexe.name}
                                label={sexe.label}
                                key={sexe.id}
                                rules={[rules.required]}
                            >
                                <InputNumber min={0} key={sexe.id} />
                            </Form.Item>
                        )
                    })}
                </div>
                <h3 className='dark-title'>Problématiques</h3>
                <div className='form-items-flex-wrap'>
                    {PROBLEM.map(prob => {
                        return (
                            <Form.Item
                                name={prob.name}
                                label={prob.label}
                                key={prob.id}
                                rules={[rules.required]}
                            >
                                <InputNumber min={0} key={prob.id} />
                            </Form.Item>
                        )
                    })}
                </div>
                <h3 className='dark-title'>Suivi</h3>
                <div className='form-items-flex'>
                    <div className='status-row'>
                        <Form.Item name='status' label='Status' rules={[rules.required]}>
                            <Search
                                controlType='select'
                                query='label'
                                baseObject={getSelect('status', logs) || null}
                                fetcher={obj => search(obj.label, STATUS)}
                            />
                        </Form.Item>
                        <Form.Item
                            name='partners'
                            label='Référence(lieu)'
                            rules={[rules.maxLength]}
                        >
                            <Search
                                mode='multiple'
                                controlType='select'
                                query='label'
                                baseObject={logs?.partners || null}
                                fetcher={obj => search(obj.label, selects.Partner)}
                            />
                        </Form.Item>
                        <Form.Item name='accompagnement_partner_id' label='Accompagnement(lieu)'>
                            <Search
                                controlType='select'
                                query='label'
                                baseObject={
                                    selects.Partner?.find(
                                        loc => loc.id === logs?.accompagnement_partner_id
                                    ) || null
                                }
                                fetcher={obj => search(obj.label, selects.Partner)}
                            />
                        </Form.Item>
                    </div>
                    <div className='notes'>
                        <Form.Item name='note' label='Notes' rules={[rules.required]}>
                            <TextArea className='text-area' value={logs?.note || null} />
                        </Form.Item>
                    </div>
                </div>
            </>
        </FormWrapper>
    )
}

export default TableLogForm
