import moment from "moment";
import { useSelector } from "react-redux";
import { AuthButton, Icon } from "components";
import { isPrint, SdsExporter } from "utils";
import { useLocation } from "react-router-dom";
import { Button } from "antd";

function Header({ title, subtitle, filters, showPrint, noLog }) {
  const currentFilters = useSelector((state) => state.filters.content);
  const user = useSelector((state) => state.user);

  const location = useLocation();
  const printMode = isPrint(location);
  const exporter = new SdsExporter({
    path: location.pathname,
    div: ".page-content",
  });

  const handleExport = () => {
    // const test = localStorage.getItem("auth-token");
    // localStorage.removeItem("auth-token");
    exporter.setFilters(currentFilters);
    exporter.export();
    // localStorage.setItem("auth-token", test);
  };

  return (
    <header className="page-header">
      <div className="upper-header">
        <div className="count-wrap">
          {/* <b>{Formatter.Number(count)}</b> */}
          <span>EMMIS {moment().format("YYYY")}</span>
        </div>

        <div className="right-part">
          <div className="user-filters-wrap">{filters && filters}</div>
          {user.logged && (
            <div
              className="user-actions"
              style={{ display: printMode ? "none" : "flex" }}
            >
              <AuthButton />
            </div>
          )}
        </div>
      </div>

      <div className="display-infos">
        <small className="subtitle">
          {!printMode && showPrint && (
            <Button onClick={handleExport}>
              <Icon>print</Icon>
              <i>Imprimer</i>
            </Button>
          )}
          {subtitle}
        </small>
        <h1>{title}</h1>
      </div>
    </header>
  );
}

export default Header;
