import { API_URL } from 'constants/api'
import { logout } from 'slices/user.slice'

import store from 'store'
import { ls } from 'utils'

export class ApiError extends Error {
    constructor(e, name, status) {
        super(e?.message || 'Une erreur est survenue')
        this.name = name
        this.status = status
        this.error = e
    }
}

/**
 * Utils : API
 * ---
 * Functions to help the interactions with the API.
 */

/**
 * Generates the header for a logged in user.
 */
export function header() {
    const token = ls.get('auth-token')
    return token ? { Authorization: token } : {}
}

/**
 * Handles the API's querying.
 * Fetch, then if error detected in server's response, format it and handle it correctly.
 * @params  { String }  route   the path to query.
 * @params  { String }  params  the params to supply to fetch API.
 * @params  { Function }    callback    a callback to format the server's response
 */
export async function queryAPI(
    route,
    params,
    logIfError = false,
    callback = (_, result) => result
) {
    if (!route || !params) return {}
    let response, result

    // Fetching
    try {
        response = await fetch(`${API_URL}/${route}`, params)
    } catch (e) {
        throw new ApiError(e, 'request_error', response.status)
    }

    // Ignore decoding errors
    try {
        result = await response.json()
    } catch (e) {
        if (logIfError) log(e)
    }

    if (response?.status === 401) {
        store.dispatch(logout())
    }

    // Error scouting
    if (!response.ok || result?.['errors']) {
        const e = new ApiError(result?.errors, result?.error_id, response.status)
        if (logIfError) log(e)
        throw e
    }

    // Return processed value
    return callback(response, result)
}

export function serialize(obj) {
    let str = []
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                obj[p].forEach(val =>
                    str.push(encodeURIComponent(p) + '[]=' + encodeURIComponent(val))
                )
            } else {
                if (typeof obj[p] !== 'number' && !obj[p]) continue
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }
        }
    }

    return str.join('&')
}

/**
 * Function to build form data from Object + recursive call to do so
 */
export function objectToFormData(object) {
    const formData = new FormData()
    buildFormData(formData, object)
    return formData
}
// TO CHECK!
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        let value = data
        if (value == null) value = ''
        if (value === true) value = 1
        formData.append(parentKey, value)
    }
}

/**
 * Log a given error.
 */
export function log(e) {
    const state = store.getState()
    const user = state?.user?.infos || {}

    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },
        body: JSON.stringify({
            debug_log: {
                date: new Date(),
                type_log: e.status,
                message: e.error || null,
                stack: e.error_id || null,
                user_id: user.id,
            },
        }),
    }

    fetch(`${API_URL}/debug_logs`, params)
}

export function formatItemWithAvatar(data) {
    return {
        ...data,
        avatar: data?.avatar_url
            ? [
                  {
                      uid: 1,
                      name: 'Photo actuelle',
                      status: 'done',
                      keep: true,
                      url: data?.avatar_url,
                  },
              ]
            : null,
    }
}

export function exportItemWithAvatar(d) {
    let data = { ...d }
    const avatarData = data?.avatar?.[0]

    delete data.avatar_url
    delete data.avatar

    data.preserve_avatar = avatarData?.keep ? 1 : 0
    if (!data.preserve_avatar && avatarData) data.avatar = avatarData?.originFileObj || null

    return data
}
