import React, { useEffect, useState } from 'react'
import { DatePicker, Form } from 'antd'
import moment from 'moment'
import Search from 'components/Search'
import { rules } from 'utils'
import Fuse from 'fuse.js'
import {
    ARRONDISSEMENTS,
    CALL_PRIORITY,
    CALL_TYPE,
    FREQUENCY,
    OUINONNA,
    STATUS,
    TYPE_FORM,
} from 'constants/formvalues'
// https://github.com/ant-design/ant-design/issues/22185

export const CustomDatePicker = props => {
    const { value, onChange, form, ...other } = props
    const time = value ? moment(value) : null
    const triggerChange = (_, dateStr) => {
        onChange && onChange(dateStr)
    }

    return <DatePicker defaultValue={time} onChange={triggerChange} {...other} />
}

const disabledDate = current => {
    // Can not select days after today
    return current && current > moment().endOf('day')
}

export function fuzzySearch(list, label) {
    if (!label) return list
    const fuse = new Fuse(list, { keys: ['label', 'surname', 'name'] })
    return fuse.search(label).map(result => result.item)
}

export async function search(label, select) {
    return {
        data: fuzzySearch(select, label),
    }
}

export const getSelect = (name, intervention) => {
    switch (name) {
        case 'type_form':
            return TYPE_FORM.find(type => type.id === intervention.type_form)
        case 'status':
            return STATUS.find(type => type.id === intervention.status)
        case 'call_priority':
            return CALL_PRIORITY.find(type => type.id === intervention.call_priority)
        case 'frequency':
            return FREQUENCY.find(type => type.id === intervention.frequency)
        case 'appel_conclusion':
            return CALL_TYPE.find(type => type.id === intervention.appel_conclusion)
        case 'presence_police':
            return OUINONNA.find(type => type.id === intervention.presence_police)
        case 'arrondissement_id':
            return ARRONDISSEMENTS.find(type => type.id === intervention.arrondissement_id)
        case 'district_id':
            return ARRONDISSEMENTS[intervention.arrondissement_id - 1].districts.find(
                type => type.id === intervention.district_id
            )
        default:
            break
    }
}
function InterventionStep1(props) {
    const { form, intervention, selects, activeStep } = props
    const { User } = selects
    const [date, setDate] = useState(intervention?.date)

    useEffect(() => {
        if (intervention.date) {
            setDate(intervention.date)
        }
    }, [intervention])

    return (
        <div className='intervention-step-1'>
            <div className='inputs-wrap-1'>
                <Form.Item
                    name='date'
                    label='Date'
                    rules={activeStep === 0 ? [rules.required] : null}
                >
                    <CustomDatePicker
                        value={date}
                        onChange={setDate}
                        allowClear={false}
                        form={form}
                        disabledDate={disabledDate}
                    />
                </Form.Item>
                <Form.Item
                    name='binome_id'
                    label='Binôme'
                    rules={activeStep === 0 ? [rules.required] : null}
                >
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={User?.find(user => user.id === intervention?.binome_id) || null}
                        fetcher={obj => search(obj.label, selects.User)}
                    />
                </Form.Item>
                <Form.Item
                    name='type_form'
                    label='Type'
                    rules={activeStep === 0 ? [rules.required] : null}
                >
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={getSelect('type_form', intervention) || null}
                        fetcher={obj => search(obj.label, TYPE_FORM)}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

export default InterventionStep1
