import { Spin } from 'antd'
import { CountUp, Header, Helmet } from 'components'
import { Bar, Donut, HorizontalBar } from 'components/Charts'
import { DEMARCHE, OUINONNA, TYPE_PARTNERS } from 'constants/formvalues'
import { useStats } from 'hooks'
import _ from 'lodash'
import { useEffect, useState } from 'react'

const Problematiques = props => {
    const { data } = props

    let newData = [
        {
            id: 1,
            label: 'Stabilité résidentielle',
            value: 0,
            name: 'problem_stabilite_residentielle',
        },
        {
            id: 2,
            label: 'Itinérance chronique',
            value: 0,
            name: 'problem_itinerance_chronique',
        },
        {
            id: 3,
            label: 'Itinérance cyclique',
            value: 0,
            name: 'problem_intinerance_cyclique',
        },
        {
            id: 4,
            label: 'Itinérance situationnelle',
            value: 0,
            name: 'problem_intinerance_situationelle',
        },
        { id: 5, label: 'En appartement', value: 0, name: 'problem_appartement' },
        { id: 6, label: 'Toxicomanie', value: 0, name: 'problem_toxicomanie' },
        { id: 7, label: 'Alcoolisme', value: 0, name: 'problem_alcoolisme' },
        { id: 8, label: 'Jeu excessif', value: 0, name: 'problem_jeu' },
        { id: 9, label: 'Judiciaire', value: 0, name: 'problem_judiciaire' },
        {
            id: 10,
            label: 'Travail du sexe',
            value: 0,
            name: 'problem_travail_sexe',
        },
        { id: 11, label: 'Santé mentale', value: 0, name: 'problem_sante_mentale' },
        {
            id: 12,
            label: 'Santé physique',
            value: 0,
            name: 'problem_sante_physique',
        },
        {
            id: 13,
            label: 'Violence / intimidation acteur',
            value: 0,
            name: 'problem_violence_acteur',
        },
        {
            id: 14,
            label: 'Violence / intimidation victime',
            value: 0,
            name: 'problem_violence_victime',
        },
        {
            id: 15,
            label: 'Troubles de comportement',
            value: 0,
            name: 'problem_trouble_comportement',
        },
        { id: 16, label: 'Fugue', value: 0, name: 'problem_fugue' },
        {
            id: 17,
            label: "Demandeurs d'asile",
            value: 0,
            name: 'problem_demandeur_asile',
        },
        { id: 18, label: 'Besoins de base', value: 0, name: 'problem_besoin_base' },
        {
            id: 19,
            label: 'Réfugié',
            name: 'problem_refugee',
            value: 0,
        },
        // {
        // 	id: 20,
        // 	label: "Trouble de l'usage de l'alcool",
        // 	name: "problem_usage_alcool",
        // 	value: 0,
        // },
    ]
    _.forEach(data[0], (val, key) => {
        const obj = newData.find(nd => nd.name === key)
        if (obj) obj.value += val
    })
    const sum = Object.values(data).reduce((acc, val) => acc + val, 0)
    return sum.length > 0 ? (
        <HorizontalBar height={200} data={newData} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}

const TotalThisYear = props => {
    const { data } = props
    return <CountUp cName='stats-interventions'>{data[0]?.total_count || 0}</CountUp>
}

const Demarches = props => {
    const { data } = props
    let newData = [
        { value: 0, id: 1, label: 'Observation', name: 'action_observation' },
        { value: 0, id: 2, label: 'Prise de contact', name: 'action_contact' },
        { value: 0, id: 3, label: 'Médiation', name: 'action_mediation' },
        {
            value: 0,
            id: 4,
            label: 'Réduction des méfaits',
            name: 'action_reduction_mefait',
        },
        { value: 0, id: 5, label: 'Gestion de crise', name: 'action_crise' },
        {
            value: 0,
            id: 6,
            label: 'Seringues ramassés',
            name: 'action_seringues',
        },
        {
            value: 0,
            id: 7,
            label: 'Distribution de titres STM',
            name: 'action_distribution_stm',
        },
        {
            value: 0,
            id: 8,
            label: 'Gestion des incivilités',
            name: 'action_incivilites',
        },
        {
            value: 0,
            id: 9,
            label: 'Premiers soins/naloxone',
            name: 'action_premier_soin',
        },
        {
            value: 0,
            id: 10,
            label: 'Intervention de soutien',
            name: 'action_soutien',
        },
        {
            value: 0,
            id: 11,
            label: 'Prise des signes vitaux',
            name: 'action_signes_vitaux',
        },
        {
            value: 0,
            id: 12,
            label: 'Gestion de plainte citoyenne',
            name: 'action_plainte_citoyenne',
        },
        {
            value: 0,
            id: 13,
            label: 'Gestion de plainte commerçant',
            name: 'action_plainte_commercant',
        },
        {
            value: 0,
            id: 14,
            label: 'Accompagnement',
            name: 'action_accompagnement',
        },
        {
            value: 0,
            id: 15,
            label: 'Distribution de besoins de base',
            name: 'action_besoin_base',
        },
        { value: 0, id: 16, label: 'Autre', name: 'action_autre' },
    ]
    _.forEach(data[0], (val, key) => {
        const obj = newData.find(nd => nd.name === key)
        if (obj) obj.value += val
    })
    const sum = Object.values(data).reduce((acc, val) => acc + val, 0)
    return sum.length > 0 ? (
        <HorizontalBar height={200} data={newData} />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}

const PoliceGraph = props => {
    const { data } = props
    let newData = {}
    newData = _.map(
        data.filter(d => d.id),
        d => {
            return {
                label: OUINONNA.find(sex => sex.id === d?.id).label,
                value: d?.value,
            }
        }
    )
    return newData.length > 0 ? (
        <Donut data={newData} height={200} />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}

const ActionsTypeDonutGraph = props => {
    const { data } = props
    let newData = {}
    if (data.length) {
        newData = _.map(
            Object.keys(data[0]).filter(d => data[0][d]),
            d => {
                return {
                    label: DEMARCHE.find(demarche => demarche.name === d)?.label,
                    value: data[0][d],
                }
            }
        )
    }

    return newData.length > 0 ? (
        <Donut data={newData} height={200} />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}

const TypeFormDonutGraph = props => {
    const { data } = props
    let newData = {}
    newData = _.map(
        data.filter(d => d.id),
        d => {
            return {
                label: TYPE_PARTNERS.find(sex => sex.id === d?.id).label,
                value: d?.value,
            }
        }
    )
    return newData.length > 0 ? (
        <Donut data={newData} height={200} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}

const Priority = props => {
    const { data } = props
    let newData = [
        { id: 1, label: 'Immédiat', value: 0 },
        { id: 2, label: '2 Prochaines heures', value: 0 },
        { id: 3, label: 'Au courant de la journée', value: 0 },
        { id: 4, label: 'Au courant de la soirée', value: 0 },
        { id: 5, label: 'Sur rendez-vous', value: 0 },
    ]
    _.forEach(data, d => {
        let tmpData = newData.find(prob => prob.id === d.id)
        if (tmpData) tmpData.value += 1
    })
    return newData.reduce((prev, next) => prev + next.value, 0) > 0 ? (
        <HorizontalBar height={200} data={newData} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}
const getTime = time => {
    if (time < 4) return 24 - (4 - time)
    return time - 4
}

const HourlyInterventions = props => {
    const { data } = props
    const tmpHours = { ...data }
    let newData = [
        { id: '00', x: '0h', y: 0 },
        { id: '01', x: '1h', y: 0 },
        { id: '02', x: '2h', y: 0 },
        { id: '03', x: '3h', y: 0 },
        { id: '04', x: '4h', y: 0 },
        { id: '05', x: '5h', y: 0 },
        { id: '06', x: '6h', y: 0 },
        { id: '07', x: '7h', y: 0 },
        { id: '08', x: '8h', y: 0 },
        { id: '09', x: '9h', y: 0 },
        { id: '10', x: '10h', y: 0 },
        { id: '11', x: '11h', y: 0 },
        { id: '12', x: '12h', y: 0 },
        { id: '13', x: '13h', y: 0 },
        { id: '14', x: '14h', y: 0 },
        { id: '15', x: '15h', y: 0 },
        { id: '16', x: '16h', y: 0 },
        { id: '17', x: '17h', y: 0 },
        { id: '18', x: '18h', y: 0 },
        { id: '19', x: '19h', y: 0 },
        { id: '20', x: '20h', y: 0 },
        { id: '21', x: '21h', y: 0 },
        { id: '22', x: '22h', y: 0 },
        { id: '23', x: '23h', y: 0 },
    ]
    Object.values(tmpHours).forEach(th => {
        if (th.hour_start) {
            newData.find(
                item => Number(item.id) === getTime(Number(th?.hour_start?.slice(11, 13)))
            ).y += 1
        }
    })
    return newData.reduce((prev, next) => {
        return prev + next.y
    }, 0) > 0 ? (
        <Bar height={300} data={newData} percentage />
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            Aucune données disponibles pour le moment.
        </div>
    )
}

function InterventionsData() {
    const { Filters, GraphBloc, StatsWrapper } = useStats('datas')
    const [isReady, setIsReady] = useState(false)
    useEffect(() => {
        if (Filters && GraphBloc && StatsWrapper) {
            setIsReady(true)
        }
        return () => {
            setIsReady(false)
        }
    }, [Filters, GraphBloc, StatsWrapper])

    return (
        <div className='interventions-data-page'>
            <Helmet title='Données des interventions' />
            <Header
                title='Données des interventions'
                subtitle='Tableau de bord'
                filters={Filters}
                showPrint
            />
            <Spin spinning={!isReady}>
                {isReady && (
                    <StatsWrapper>
                        <GraphBloc
                            className='nombre_interventions full-width'
                            title="Nombres d'interventions"
                            dataKey='total_count'
                            graph={TotalThisYear}
                        />
                        <GraphBloc
                            title='Problématiques'
                            dataKey='problematiques'
                            graph={Problematiques}
                        />
                        <GraphBloc title='Démarches prises' dataKey='actions' graph={Demarches} />
                        <GraphBloc
                            title='Présence policière requise'
                            dataKey='presence_police'
                            graph={PoliceGraph}
                        />
                        <GraphBloc
                            title="Priorité d'appel"
                            dataKey='call_priority'
                            graph={Priority}
                        />
                        <GraphBloc
                            className='full-width'
                            title='Interventions par heure de début'
                            dataKey='hour_start'
                            graph={HourlyInterventions}
                        />
                        <GraphBloc
                            title='Démarche prises'
                            dataKey='actions'
                            graph={ActionsTypeDonutGraph}
                        />
                        <GraphBloc
                            title='Provenance des appels'
                            dataKey='type_partner'
                            graph={TypeFormDonutGraph}
                            totalKey='total_form_with_partner_count'
                        />
                    </StatsWrapper>
                )}
            </Spin>
        </div>
    )
}

export default InterventionsData
