/**
 * Service : Organisations
 * ---
 * Interraction with the DB to manipulate organisations.
 */

import { header, queryAPI, serialize } from 'utils'

export const OrganisationService = {
    list,
    get,
    update,
    create,
    del,
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`organisations?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`organisations/${id}`, params)
}

async function update(organisation) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            organisation: organisation,
        }),
    }

    return await queryAPI(`organisations/${organisation.id}`, params, true)
}

async function create(organisation) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            organisation: organisation,
        }),
    }

    return await queryAPI(`organisations`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`organisations/${id}`, params, true)
}
