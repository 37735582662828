// ABILITIES
export const READ = 'read'
export const EDIT = 'edit'
export const CREATE = 'create'
export const DELETE = 'del'
export const PUBLISH = 'publish'
export const MANAGE = 'manage'
export const FILTER = 'filter'

// DB REFS
export const USER_RANK_ADMIN = 1
export const USER_RANK_INTERVENANT = 2
export const USER_RANK_PARTNER = 3

export const USER_RANKS = {
    [USER_RANK_ADMIN]: { label: 'Administrateur' },
    [USER_RANK_INTERVENANT]: { label: 'Intervenant' },
    [USER_RANK_PARTNER]: { label: 'Partenaire' },
    4: { label: 'Archivé' },
}

export const LOG_STATUS = {
    1: { label: 'Régulier' },
    2: { label: 'Action requise' },
    3: { label: 'Retour potentiel' },
}

export const FORM_STATUS = {
    1: { label: 'Complété' },
    2: { label: 'Brouillon' },
}

// UTIL
const getDict = name => {
    if (name === 'user-ranks') return USER_RANKS
    if (name === 'log-status') return LOG_STATUS
    if (name === 'form-status') return FORM_STATUS
    return {}
}

export function listify(dict) {
    let obj
    if (typeof dict === 'string') obj = getDict(dict)
    else if (typeof dict === 'object') obj = dict
    else return []

    const list = []
    for (const key in obj) list.push({ ...obj[key], id: key })

    return list
}

export function describe(dict, key) {
    return getDict(dict)?.[key]
}
