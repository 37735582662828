/**
 * Service : Intervention
 * ---
 * Interaction with the DB to manipulate interventions.
 */

import { header, queryAPI, serialize } from 'utils'

export const InterventionService = {
    list,
    get,
    updateOrCreate,
    create,
    del,
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`forms?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`forms/${id}`, params)
}

async function updateOrCreate(intervention) {
    if (intervention.id) return update(intervention)
    return create(intervention)
}

async function update(intervention) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            form: intervention,
        }),
    }

    return await queryAPI(`forms/${intervention.id}`, params, true)
}

async function create(intervention) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            form: intervention,
        }),
    }

    return await queryAPI(`forms`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`forms/${id}`, params, true)
}
