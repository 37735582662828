import { useState } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Modal, Button } from "antd";
import { Icon } from "components";

import moment from "moment";
import { InterventionService } from "services";
import { Feedback, Formatter } from "utils";
import { DATE_FORMAT_API } from "constants/app";
import { describe } from "constants/misc";

function PastInterventionsButton() {
	const [modalOpen, setModalOpen] = useState(false);
	const [interventions, setInterventions] = useState([]);

	const user = useSelector((state) => state.user);

	const fetchInterventions = async () => {
		try {
			const list = await InterventionService.list({
				user_id: user?.infos?.rang === 1 ? null : user?.infos?.id,
				order_by: "forms.created_at",
				order_direction: "desc",
				created_at: [
					moment().add(-3, "days").format(DATE_FORMAT_API),
					moment().format(DATE_FORMAT_API),
				],
			});

			setInterventions(list);
		} catch (e) {
			Feedback.Error("loading-selects", e);
		}
	};

	const handleModalToggle = () => {
		if (!modalOpen && interventions.length === 0) fetchInterventions();
		setModalOpen(!modalOpen);
	};

	return (
		<>
			<Button
				className="past-interventions-btn blue"
				onClick={handleModalToggle}>
				<Icon>edit</Icon>
				<i>Modifier une intervention passée</i>
			</Button>

			<Modal
				visible={modalOpen}
				footer={null}
				closable={true}
				onCancel={() => setModalOpen(false)}
				className="past-interventions-modal">
				<h3>Interventions passées</h3>
				<p>
					Voici la liste de vos interventions passées. Vous pouvez modifier
					celles qui furent remplies dans les 3 derniers jours seulement.
				</p>
				{interventions.length === 0 ? (
					<p className="error">
						<Icon>error</Icon>Aucune intervention trouvée...
					</p>
				) : (
					<ul>
						{interventions.map((intervention, i) => (
							<li key={i}>
								<Link
									to={`/interventions/${intervention.id}`}
									onClick={() => setModalOpen(false)}>
									<Button className="blue">
										<Icon>edit</Icon>
									</Button>
									<span>
										<b>{Formatter.DateText(intervention.date)}</b>
										<small>
											{intervention?.partner?.label || "Partenaire inconnu"}
										</small>
										<small>
											{intervention?.location?.label || "Localisation inconnue"}
										</small>
										<small>
											{intervention?.schedule?.label || "Horaire inconnu"}
										</small>
										<small>
											{describe("form-status", intervention?.status)?.label ||
												"Statut inconnu"}
										</small>
									</span>
								</Link>
							</li>
						))}
					</ul>
				)}
			</Modal>
		</>
	);
}

export default PastInterventionsButton;
