export const APP_NAME = 'EMMIS'
export const APP_SEPARATOR = '-'

export const REFRESH_RATE = 3 * 60 * 1000

export const DATE_FORMAT_API = 'YYYY-MM-DD'
export const DATE_FORMAT_TIME_API = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss'
export const DATE_TEXT_FORMAT = 'D MMMM YYYY'
export const TIME_TEXT_FORMAT = 'HH:mm:ss'
