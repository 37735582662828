import React, { Component } from 'react'
import moment from 'moment'
import { DatePicker, TimePicker } from 'antd'
import { datePickerProps } from 'utils'

/**
 * Component : TableFilters > TimeFloatingFilter
 * ---
 * Component that renders a date floating filter.
 * @prop    { Function }    disabledDate    AntDesign prop to disable certain dates
 */

class TimeFloatingFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentValue: null,
            disabledDate: props.disabledDate || null,
        }

        this.valueChanged = this.valueChanged.bind(this)
        this.onParentModelChanged = this.onParentModelChanged.bind(this)
    }

    // Filter's value change.
    valueChanged(dates) {
        if (!dates) dates = null

        this.setState({ currentValue: dates }, () => {
            const dateStrings = dates?.format('HH:mm:ss')
            let valueToUse = dates ? dateStrings : null
            this.props.parentFilterInstance(instance =>
                instance.onFloatingFilterChanged('equals', valueToUse)
            )
        })
    }

    // Called when the table gives a new value to display here.
    onParentModelChanged(parentModel) {
        let currentValue = null
        if (parentModel) {
            currentValue = parentModel.filter
        }

        this.setState({ currentValue })
    }

    render() {
        return (
            <TimePicker
                size='small'
                {...datePickerProps(true)}
                value={moment(this.state.currentValue)}
                onChange={this.valueChanged}
                disabledDate={this.state.disabledDate}
            />
        )
    }
}

export default TimeFloatingFilter
