/**
 * Service : Locations
 * ---
 * Interraction with the DB to manipulate locations.
 */

import { header, queryAPI, serialize } from 'utils'

export const LocationService = {
    list,
    get,
    update,
    create,
    del,
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`locations?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`locations/${id}`, params)
}

async function update(location) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            location: location,
        }),
    }

    return await queryAPI(`locations/${location.id}`, params, true)
}

async function create(location) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            location: location,
        }),
    }

    return await queryAPI(`locations`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`locations/${id}`, params, true)
}
