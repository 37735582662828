import InterventionStep1 from "./Step1";
import InterventionStep2 from "./Step2";
import InterventionStep3 from "./Step3";
import InterventionStep4 from "./Step4";
import InterventionStep5 from "./Step5";
import InterventionStep6 from "./Step6";

export const InterventionSteps = [
    InterventionStep1,
    InterventionStep2,
    InterventionStep3,
    InterventionStep4,
    InterventionStep5,
    InterventionStep6,
];
