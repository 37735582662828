import { Helmet, Header } from "components";

function E404Page(props) {
  return (
    <div className="e-404-page">
      <Helmet title="Page introuvable" />
      <Header title="Page introuvable" subtitle="Erreur 404" />
    </div>
  );
}

export default E404Page;
