import { useState, useEffect } from 'react'
import { Helmet, Header, Icon, PastInterventionsButton, Table } from 'components'
import { Button, Form, Steps, Popconfirm, Spin } from 'antd'
import { InterventionService, SelectService } from 'services'
import { Feedback, Formatter, history, validateInterventionCounts } from 'utils'

import { InterventionSteps } from 'components/Intervention'
import { useSelector } from 'react-redux'
import { DATE_FORMAT_API, DATE_FORMAT_TIME_API } from 'constants/app'
import moment from 'moment'

function InterventionPage(props) {
    const { id, step } = props.match.params
    const [activeStep, setActiveStep] = useState(parseInt(step) || 0)
    const user = useSelector(state => state.user)

    const [loading, setLoading] = useState(false)
    const [intervention, setIntervention] = useState({})
    const [selects, setSelects] = useState({})

    const [form] = Form.useForm()
    const [touched, setTouched] = useState(false)

    const loadIntervention = async () => {
        if (!id) return
        setLoading(true)
        try {
            const response = await InterventionService.get(id)
            response.date = Formatter.Date(response.date, DATE_FORMAT_API)
            response.hour_call = response.hour_call ? response.hour_call : null
            response.hour_start = response.hour_start ? response.hour_start : null
            response.hour_end = response.hour_end ? response.hour_end : null
            setIntervention({
                ...response,
                partners: response?.partners?.map(partner => partner.id),
            })
        } catch (e) {
            Feedback.Error('loading-intervention', e)
            history.push('/')
        } finally {
            setLoading(false)
        }
    }

    const loadSelects = async () => {
        const keys = ['Location', 'Partner', 'Problem', 'Action', 'User', 'StreetCornerNotArchive']

        if (Object.keys(selects).length === keys.length) return

        try {
            const response = await SelectService.list(keys)
            setSelects(response)
        } catch (e) {
            Feedback.Error('loading-selects', e)
        }
    }

    const updateForm = () => {
        if (!intervention.id) return
        form.resetFields()
        form.setFieldsValue({ ...intervention })
    }

    useEffect(() => {
        loadSelects()
        return () => setSelects({})
    }, [])

    useEffect(() => {
        loadIntervention()
        return () => {
            setIntervention({})
            setLoading(false)
        }
    }, [id])

    useEffect(() => updateForm(), [intervention?.id])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const handleStepChange = async (i, forceSave = false) => {
        if (!forceSave && !touched) {
            setActiveStep(i)
            scrollToTop()
            if (id) history.replace(`/interventions/${id}/${i}`)
            return
        }

        const feedback = new Feedback.Async()
        setLoading(true)

        try {
            let values = await form.validateFields()
            let tmpDate = values.date ? moment(values.date) : null
            let tmpHourCall = values.hour_call ? moment(values.hour_call) : null
            let tmpHourStart = values.hour_start ? moment(values.hour_start) : null
            let tmpHourEnd = values.hour_end ? moment(values.hour_end) : null
            if (tmpHourCall) {
                tmpHourCall.date(tmpDate.date())
                tmpHourCall.month(tmpDate.month())
                tmpHourCall.year(tmpDate.year())
                values.hour_call = tmpHourCall
            }
            if (tmpHourStart) {
                tmpHourStart.date(tmpDate.date())
                tmpHourStart.month(tmpDate.month())
                tmpHourStart.year(tmpDate.year())
                values.hour_start = tmpHourStart
            }
            if (tmpHourEnd) {
                tmpHourEnd.date(tmpDate.date())
                tmpHourEnd.month(tmpDate.month())
                tmpHourEnd.year(tmpDate.year())
                values.hour_end = tmpHourEnd
            }
            checkForMismatch(values)
            const updated = await InterventionService.updateOrCreate({
                id,
                user_id: user?.infos?.id,
                ...values,
                lng: sessionStorage.getItem('@lng'),
                lat: sessionStorage.getItem('@lat'),
                hour_call: values.hour_call ? moment(values.hour_call) : null,
                hour_start: values.hour_start ? moment(values.hour_start) : null,
                hour_end: values.hour_end ? moment(values.hour_end) : null,
            })
            setIntervention(updated)
            setTouched(false)
            setActiveStep(i)
            scrollToTop()

            feedback.success('Enregistrement complété avec succès')
            history.replace(`/interventions/${updated.id}/${i}`)
        } catch (e) {
            if (e.errorFields)
                feedback.error('Attention, certains champs obligatoires ne furent pas remplis.')
            else feedback.error()
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = async () => {
        sessionStorage.clear('homeData')
        const feedback = new Feedback.Async()
        setLoading(true)
        try {
            const values = await form.validateFields()
            checkForMismatch(values)
            await InterventionService.updateOrCreate({
                id,
                user_id: user?.infos?.id,
                partner_ids: values.partners,
                ...values,
            })
            setTouched(false)
            feedback.success('Rapport complété avec succès')
            history.push('/')
        } catch (e) {
            feedback.error()
        } finally {
            setLoading(false)
        }
    }

    const handleDelete = async () => {
        sessionStorage.clear('homeData')
        const feedback = new Feedback.Async()
        setLoading(true)
        try {
            if (id) await InterventionService.del(id)
            feedback.success("L'intervention a été suprimée avec succès")
            history.push('/')
        } catch (e) {
            feedback.error()
        } finally {
            setLoading(false)
        }
    }

    const handleCancel = async () => {
        history.push('/')
    }

    const handleFormValuesChange = (changes, values) => {
        if (!changes) return
        setTouched(true)
        // checkForMismatch(values, changes); //I NEED TO ADD THIS BACK AND FIX IT!
    }

    const checkForMismatch = (values, changes) => {
        const [mismatch, location] = validateInterventionCounts(values, changes)
        if (!mismatch) return

        Feedback.Warn(
            <>
                <b>Attention, nous avons détecté certaines incohérences dans le formulaire.</b>
                {location && (
                    <>
                        <span>Voici plus d'informations :</span>
                        <ul>
                            {Object.keys(location).map(key => (
                                <li>
                                    <b>{key} :</b>{' '}
                                    {location[key] === 'above'
                                        ? 'La valeur est probablement trop grande'
                                        : 'La valeur semble inadéquate par rapport au reste'}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </>
        )
    }

    const FormStep = index => {
        const step = steps[index]
        const Content = step.content || null

        return (
            <div
                className={`form-step form-step-${index}`}
                style={{ display: index === activeStep ? 'block' : 'none' }}
            >
                <h2>
                    {index + 1}. {step.title}
                </h2>
                <div className='form-step-content'>
                    {Content && (
                        <Content
                            selects={selects}
                            form={form}
                            intervention={intervention}
                            activeStep={activeStep}
                        />
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className='invervention-page'>
            <Helmet title='Compléter un rapport' />
            <Header title="Rapport d'intervention" subtitle='Administration' />
            <PastInterventionsButton />

            <Spin spinning={loading}>
                <div className='steps-wrap'>
                    <Steps
                        className={!intervention.id && 'disabled'}
                        current={activeStep}
                        onChange={i => (intervention.id ? handleStepChange(i, false) : null)}
                        progressDot={(dot, { index }) => <Icon>{steps[index].icon}</Icon>}
                    >
                        {steps.map((item, i) => (
                            <Steps.Step
                                key={i}
                                title={`${i + 1}. ${item.title}`}
                                icon={
                                    activeStep > i ? (
                                        <Icon>complete</Icon>
                                    ) : (
                                        <Icon>{item.icon}</Icon>
                                    )
                                }
                            />
                        ))}
                    </Steps>
                </div>

                <div className='form-wrap'>
                    <Spin spinning={Object.values(selects).length === 0}>
                        <Form form={form} layout='vertical' onValuesChange={handleFormValuesChange}>
                            {FormStep(0)}
                            {FormStep(1)}
                            {FormStep(2)}
                            {FormStep(3)}
                            {FormStep(4)}
                            {FormStep(5)}

                            {/* <div
              className={`form-step form-step-4`}
              style={{ display: activeStep === 4 ? "block" : "none" }}
              >
              <h2>5. Journal de bord</h2>
              {intervention.id ? (
                <Table
                instance="logs-with-create"
                fixedFilters={{ form_id: intervention.id }}
                />
                ) : (
                  <></>
                  )}
                </div> */}
                        </Form>
                    </Spin>
                </div>

                <div className='buttons'>
                    <div className='extra-btns'>
                        <Popconfirm
                            title='Attention, cette action va supprimer la saisie en cours. Cette action est aussi irréversible. Voulez-vous vraiment continuer?'
                            okButtonProps={{ className: 'red' }}
                            okText='Oui'
                            cancelText='Non'
                            onConfirm={handleDelete}
                        >
                            <Button className='red'>
                                <Icon>delete</Icon>
                                <i>Supprimer le rapport</i>
                            </Button>
                        </Popconfirm>
                        <Button onClick={handleCancel}>Annuler</Button>
                    </div>
                    <div className='main-btns'>
                        <Button
                            disabled={activeStep === 0}
                            onClick={() => handleStepChange(activeStep - 1)}
                        >
                            <Icon>back</Icon>
                            <i>Précédent</i>
                        </Button>
                        {activeStep !== steps.length - 1 ? (
                            <Button
                                className='blue'
                                onClick={() => handleStepChange(activeStep + 1, true)}
                            >
                                <Icon>save</Icon>
                                <i>Sauvegarder et passer à l'étape suivante</i>
                                <Icon>to</Icon>
                            </Button>
                        ) : (
                            <Button className='orange' onClick={handleSubmit}>
                                <Icon>complete</Icon>
                                <i>Compléter le rapport</i>
                                <Icon>to</Icon>
                            </Button>
                        )}
                    </div>
                </div>
            </Spin>
        </div>
    )
}

const steps = [
    {
        title: 'Info Intervention',
        icon: 'location',
        content: InterventionSteps[0],
    },
    {
        title: 'Info Appel',
        icon: 'phone',
        content: InterventionSteps[1],
    },
    {
        title: "Données d'intervention",
        icon: 'insert_chart',
        content: InterventionSteps[2],
    },
    {
        title: 'Démographie',
        icon: 'demography',
        content: InterventionSteps[3],
    },
    {
        title: 'Problématiques',
        icon: 'problems_page',
        content: InterventionSteps[4],
    },
    {
        title: 'Suivi',
        icon: 'see_more',
        content: InterventionSteps[5],
    },
]

export default InterventionPage
