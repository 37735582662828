/**
 * Constants : API
 * ---
 * File that reads the API's configurations.
 */

const env = process.env.REACT_APP_STAGE || 'local'
const configs = (window.Configs || {})[env] || {}

export const API_URL = configs.API || '/'
export const MAPBOX_TOKEN =
    'pk.eyJ1IjoiZmVsaXgwNGgxMSIsImEiOiJja3FoMHlwdmwwMmJtMnhtbXU1YzFnMWhwIn0.TRkK8NmVHCKPm59aveXxqw'
