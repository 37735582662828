/**
 * Service : Partners
 * ---
 * Interraction with the DB to manipulate partners.
 */

import { header, objectToFormData, queryAPI, serialize } from 'utils'

export const PartnerService = {
    list,
    get,
    update,
    create,
    del,
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`partners?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`partners/${id}`, params)
}

async function update(partner) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            ...header(),
        },

        body: objectToFormData({ partner }),
    }

    return await queryAPI(`partners/${partner.id}`, params, true)
}

async function create(partner) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            ...header(),
        },

        body: objectToFormData({ partner }),
    }

    return await queryAPI(`partners`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`partners/${id}`, params, true)
}
