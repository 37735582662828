import React, { Component } from 'react'
import { Checkbox } from 'antd'

const transposeValueToString = value => {
    if (value === null) return null
    return value === true ? '1' : '0'
}

const transposeParentModelToValue = parentModel => {
    if (!parentModel) return null
    return parentModel.filter === '1'
}

/**
 * Component : TableFilters > BoolFloatingFilter
 * ---
 * Component that renders a boolean floating filter.
 * @prop    { String }  label  The label to display
 */

class BoolFloatingFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            label: props.label,
            currentValue: null,
        }

        this.valueChanged = this.valueChanged.bind(this)
        this.onParentModelChanged = this.onParentModelChanged.bind(this)
    }

    // Filter's value change.
    valueChanged(event) {
        let { checked } = event.target
        console.log('checked', checked)
        if (this.state.currentValue === false && checked === true) checked = null
        this.setState({ currentValue: checked }, () => {
            let valueToUse = transposeValueToString(this.state.currentValue)
            this.props.parentFilterInstance(instance =>
                instance.onFloatingFilterChanged('equals', valueToUse)
            )
        })
    }

    // Called when the table gives a new value to display here.
    onParentModelChanged(parentModel) {
        this.setState({
            currentValue: transposeParentModelToValue(parentModel),
        })
    }

    render() {
        // Can't use withTranslation here, it messes with the function definitions.
        return (
            <Checkbox
                indeterminate={this.state.currentValue === null}
                checked={this.state.currentValue}
                onChange={this.valueChanged}
            >
                {this.state.label}
            </Checkbox>
        )
    }
}

export default BoolFloatingFilter
