import moment from "moment";
import {
  DATE_FORMAT,
  DATE_TEXT_FORMAT,
  DATE_TIME_FORMAT,
  TIME_TEXT_FORMAT,
} from "constants/app";

/**
 * Utils : Format
 * ---
 * Formatting functions
 */

export const Formatter = {
  Date: formatDate,
  Time: formatTime,
  DateTime: formatDateTime,
  DateText: formatDateText,
  DateRange: formatDateRange,
  Slug: formatPascalCaseToSlug,
  Url: formatStringToUrl,
  RGB: formatHexToRGB,
  Number: formatNumber,
};

function formatDate(date, format = DATE_FORMAT) {
  if (date instanceof moment) return date.format(format);
  return moment.parseZone(date).format(format);
}

function formatTime(date, format = TIME_TEXT_FORMAT) {
  if (date instanceof moment) return date.format(format);
  return moment.parseZone(date).format(format);
}

function formatDateTime(dateTime, format = DATE_TIME_FORMAT) {
  if (dateTime instanceof moment) return dateTime.format(format);
  return moment.parseZone(dateTime).format(format);
}

function formatDateText(dateText) {
  if (dateText instanceof moment) return dateText.format(DATE_TEXT_FORMAT);
  return moment.parseZone(dateText).format(DATE_TEXT_FORMAT);
}

function formatDateRange(range, format = "LL") {
  if (!range) return "Toutes dates";
  const [start, end] = range;
  return `Du ${formatDate(start, format)} au ${formatDate(end, format)}`;
}

function formatPascalCaseToSlug(str = "") {
  return str
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => "_" + y.toLowerCase())
    .replace(/^_/, "");
}

function formatStringToUrl(str = "") {
  return encodeURIComponent(str.replace(/ /g, "-").toLowerCase());
}

function formatHexToRGB(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function formatNumber(number) {
  if (!number) return "";
  if (isNaN(number))
    throw new Error("Number expected, something else received.");
  return `${String(number)}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
