const URL_WITHOUT_PROTOCOL =
    /^(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/
const HAS_PROTOCOL = /^https?:\/\//

function ExternalLink({ children: url, label, targetBlank, onMouseEnter, onMouseLeave }) {
    if (!url) return null
    if (url.match(URL_WITHOUT_PROTOCOL)) url = 'http://' + url
    if (!url.match(HAS_PROTOCOL)) return url
    // Les props onMouseEnter et onMouseLeave sont nécessaires pour qu'un Popover puisse marcher
    return (
        <a
            href={url}
            rel='noopener noreferrer'
            target={targetBlank && '_blank'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {label || url}
        </a>
    )
}

export default ExternalLink
