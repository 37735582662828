import XLSX from 'xlsx'
import { csv } from 'csvtojson'
import { saveAs } from 'file-saver'

/**
 * Utils : Tables
 * ---
 * Functions for the tables.
 */

export function downloadCsvAsXlsx(csvString, filename = 'export') {
    csv()
        .fromString(csvString)
        .then(json => {
            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet(json)
            XLSX.utils.book_append_sheet(wb, ws, '')

            const wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'binary',
            })

            const buf = new ArrayBuffer(wbout.length)

            const view = new Uint8Array(buf)

            for (let i = 0; i !== wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xff

            saveAs(new Blob([buf], { type: 'application/octet-stream' }), `${filename}.xlsx`)
        })
}

export function filterModelToObject(filterModel = {}) {
    if (!filterModel) return {}

    let obj = {}
    for (let colId in filterModel) {
        const model = filterModel[colId]

        let value = ''
        try {
            value = JSON.parse(model.filter)
        } catch (e) {
            value = model.filter
        }

        obj[colId] = value
    }

    return obj
}
