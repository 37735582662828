import { Form, Input } from "antd";
import { useTableForm } from "hooks";
import { rules } from "utils";

const texts = {
    create: {
        title: "Nouveau témoignage",
    },
    default: {
        title: "Détails d'un témoignage",
    },
    edit: {
        title: "Édition d'un témoignage",
    },
};

function TableTestimonialForm(props) {
    const { FormWrapper } = useTableForm(props, texts, []);

    return (
        <FormWrapper>
            <>
                <Form.Item label="Nom" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Entreprise" name="corporation">
                    <Input />
                </Form.Item>
                <Form.Item label="Fonction" name="function">
                    <Input />
                </Form.Item>
                <Form.Item label="Témoignage" name="text">
                    <Input />
                </Form.Item>
            </>
        </FormWrapper>
    );
}

export default TableTestimonialForm;
