import { useState } from 'react'
import { CountUp } from 'components'
import { getObjectTotal } from 'utils'

function TotalTable({ data }) {
    const [hint, setHint] = useState(false)
    const total = getObjectTotal(data)
    return (
        <ul className='total-table-wrap' onMouseOut={() => setHint(false)}>
            {data.map((d, i) => (
                <li
                    key={i}
                    className={hint ? (d.label === hint.label ? 'hovered' : 'not-hovered') : ''}
                    onMouseEnter={() => setHint(d)}
                >
                    <span className='table-label'>
                        <b>{d.label}</b>
                        <CountUp>{d.value}</CountUp>
                    </span>
                </li>
            ))}
            <li>
                <span className='table-label'>
                    <b>Total</b>
                    <CountUp>{total}</CountUp>
                </span>
            </li>
        </ul>
    )
}

export default TotalTable
