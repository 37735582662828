import { createSlice } from '@reduxjs/toolkit'

export const CounterSlice = createSlice({
    name: 'counter',
    initialState: {
        interventions: 0,
    },
    reducers: {
        updateInterventionsCount: (state, action) => {
            const value = action.payload
            state.interventions = value
        },
    },
})

export const { updateInterventionsCount } = CounterSlice.actions

export default CounterSlice.reducer
