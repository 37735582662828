import { createSlice } from '@reduxjs/toolkit'

export const TableSlice = createSlice({
    name: 'table',
    initialState: { lookups: {} },
    reducers: {
        updateTableLookups: (state, action) => {
            state.lookups = {
                ...state.lookups,
                ...action.payload,
            }
        },
    },
})

export const { updateTableLookups } = TableSlice.actions

export default TableSlice.reducer
