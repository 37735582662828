export const TYPE_FORM = [
    { id: 1, label: 'Appel' },
    { id: 2, label: 'Ponctuel' },
    { id: 3, label: 'Planifié' },
    { id: 4, label: 'Campement' },
    { id: 5, label: 'N/D' },
    { id: 6, label: 'Appel Citoyen' },
    { id: 7, label: 'Appel commerçant' },
    { id: 8, label: 'Courriel citoyens' },
]

export const TYPE_PARTNERS = [
    { id: 1, label: 'Inconnu' },
    { id: 2, label: 'Organisme communautaire' },
    { id: 3, label: 'Réseau de santé' },
    { id: 4, label: 'Services policiers' },
    { id: 5, label: "Services d'incendies" },
    { id: 6, label: 'Commerce' },
    { id: 7, label: 'Autre' },
    { id: 8, label: 'Ville/arrondissement' },
]

export const CALL_TYPE = [
    { id: 1, label: 'Appel accepté' },
    { id: 2, label: 'Appel refusé: Intervention en cours' },
    { id: 3, label: 'Appel refusé: Hors territoire' },
    { id: 4, label: "Appel refusé: Hors des heures d'opération" },
    { id: 5, label: 'Appel refusé: Autre' },
]

export const CALL_PRIORITY = [
    { id: 1, label: 'Immédiat' },
    { id: 2, label: 'Au courant de la journée/soirée' },
    { id: 3, label: 'Sur rendez-vous' },
    { id: 4, label: 'N/D' },
]

export const OTHER_PARTNER = [
    { id: 1, label: 'Non' },
    { id: 2, label: 'Policier' },
    { id: 3, label: 'Travailleur de rue' },
    { id: 4, label: 'Intervenant psychosocial' },
    { id: 5, label: 'Ambulanciers' },
    { id: 6, label: 'Pompiers' },
    { id: 7, label: 'Agents du métro' },
    { id: 8, label: 'Gardiens de parc' },
    { id: 9, label: 'Agent de sécurité privée' },
    { id: 10, label: 'Autre' },
]

export const SEXE = [
    { id: 1, label: 'Homme', name: 'sex_male' },
    { id: 2, label: 'Femme', name: 'sex_female' },
    { id: 3, label: 'Trans', name: 'sex_trans' },
]

export const LANGAGE = [
    { id: 1, label: 'Anglais', name: 'langage_english' },
    { id: 2, label: 'Français', name: 'langage_french' },
    { id: 3, label: 'Inconnu/autre', name: 'langage_other' },
]

export const FREQUENCY = [
    { id: 1, label: 'Nouvel usager' },
    { id: 2, label: 'Usager cyclique' },
    { id: 3, label: 'Usager régulier' },
]

export const ORIGIN = [
    { id: 1, label: 'Caucasien', name: 'origin_caucas' },
    { id: 2, label: 'Minorité visible', name: 'origin_minority' },
    { id: 3, label: 'Autochtone/Inuit', name: 'origin_autocht' },
]

export const AGE = [
    { id: 1, label: 'Moins de 18 ans', name: 'age_under18' },
    { id: 2, label: '18 - 24 ans', name: 'age_1824' },
    { id: 3, label: '25 - 39 ans', name: 'age_2539' },
    { id: 4, label: '40 - 54 ans', name: 'age_4054' },
    { id: 5, label: '55 - 64 ans', name: 'age_5564' },
    { id: 6, label: '65 ans et plus', name: 'age_65more' },
]

export const OUINONNA = [
    { id: 1, label: 'Oui' },
    { id: 2, label: 'Non' },
    { id: 3, label: 'N/D' },
]

export const PROBLEM = [
    {
        id: 1,
        label: 'Stabilité résidentielle',
        name: 'problem_stabilite_residentielle',
    },
    {
        id: 2,
        label: 'Itinérance chronique',
        name: 'problem_itinerance_chronique',
    },
    { id: 3, label: 'Itinérance cyclique', name: 'problem_intinerance_cyclique' },
    {
        id: 4,
        label: 'Itinérance situationnelle',
        name: 'problem_intinerance_situationelle',
    },
    { id: 5, label: 'En appartement', name: 'problem_appartement' },
    { id: 6, label: 'Toxicomanie', name: 'problem_toxicomanie' },
    { id: 7, label: 'Alcoolisme', name: 'problem_alcoolisme' },
    { id: 8, label: 'Jeu excessif', name: 'problem_jeu' },
    { id: 9, label: 'Judiciaire', name: 'problem_judiciaire' },
    { id: 10, label: 'Travail du sexe', name: 'problem_travail_sexe' },
    { id: 11, label: 'Santé Mentale', name: 'problem_sante_mentale' },
    { id: 12, label: 'Santé Physique', name: 'problem_sante_physique' },
    {
        id: 13,
        label: 'Violence/intimidation acteur',
        name: 'problem_violence_acteur',
    },
    {
        id: 14,
        label: 'Violence/intimidation victime',
        name: 'problem_violence_victime',
    },
    {
        id: 15,
        label: 'Troubles de comportement',
        name: 'problem_trouble_comportement',
    },
    { id: 16, label: 'Fugue', name: 'problem_fugue' },
    { id: 17, label: "Demandeurs d'asile", name: 'problem_demandeur_asile' },
    { id: 18, label: 'Besoins de base', name: 'problem_besoin_base' },
    {
        id: 19,
        label: 'Réfugié',
        name: 'problem_refugee',
    },
    // {
    // 	id: 20,
    // 	label: "Trouble de l'usage de l'alcool",
    // 	name: "problem_usage_alcool",
    // },
]

export const DEMARCHE = [
    { id: 1, label: 'Observation', name: 'action_observation' },
    { id: 2, label: 'Prise de contact', name: 'action_contact' },
    { id: 3, label: 'Médiation', name: 'action_mediation' },
    { id: 4, label: 'Réduction des méfaits', name: 'action_reduction_mefait' },
    { id: 5, label: 'Gestion de crise', name: 'action_crise' },
    {
        id: 6,
        label: 'Seringues ramassés',
        name: 'action_seringues',
    },
    {
        id: 7,
        label: 'Distribution de titres STM',
        name: 'action_distribution_stm',
    },
    { id: 8, label: 'Gestion des incivilités', name: 'action_incivilites' },
    { id: 9, label: 'Premiers soins/naloxone', name: 'action_premier_soin' },
    { id: 10, label: 'Intervention de soutien', name: 'action_soutien' },
    { id: 11, label: 'Prise des signes vitaux', name: 'action_signes_vitaux' },
    {
        id: 12,
        label: 'Gestion de plainte citoyenne',
        name: 'action_plainte_citoyenne',
    },
    {
        id: 13,
        label: 'Gestion de plainte commerçant',
        name: 'action_plainte_commercant',
    },
    { id: 14, label: 'Accompagnement', name: 'action_accompagnement' },
    {
        id: 15,
        label: 'Distribution de besoins de base',
        name: 'action_besoin_base',
    },
    { id: 16, label: 'Autre', name: 'action_autre' },
    { id: 17, label: 'Prise de contact - Riverain', name: 'action_contact_riv' },
    { id: 18, label: 'Médiation - Riverain', name: 'action_mediation_riv' },
]

export const AGGRESSIF = [
    { id: 1, label: 'Physiquement' },
    { id: 2, label: 'Verbalement' },
    { id: 3, label: 'Objet' },
    { id: 4, label: 'Non' },
]

export const STATUS = [
    { id: 1, label: 'Régulier' },
    { id: 2, label: 'Action/suivi requis' },
    { id: 3, label: 'Retour potentiel' },
    { id: 4, label: 'N/D' },
]

export const getDistricts = () => {
    const districtArray = ARRONDISSEMENTS.map(arrondissement => {
        return arrondissement.districts
    })
    return districtArray.flat()
}

export const ARRONDISSEMENTS = [
    {
        id: 1,
        label: 'Ahuntsic-Cartierville',
        districts: [
            { id: 11, label: 'Ahuntsic' },
            { id: 12, label: 'Bordeaux-Cartierville' },
            { id: 13, label: 'Saint-Sulpice' },
            { id: 14, label: 'Sault-au-Récollet' },
        ],
    },
    {
        id: 2,
        label: 'Anjou',
        districts: [
            { id: 21, label: 'Est' },
            { id: 22, label: 'Centre' },
            { id: 23, label: 'Ouest' },
        ],
    },
    {
        id: 3,
        label: 'Côte-des-Neiges-Notre-Dame-de-Grâce',
        districts: [
            { id: 31, label: 'Côte-des-Neiges' },
            { id: 32, label: 'Darlington' },
            { id: 33, label: 'Loyola' },
            { id: 34, label: 'Notre-Dame-de-Grâce' },
            { id: 35, label: 'Snowdon' },
        ],
    },
    {
        id: 4,
        label: 'Lachine',
        districts: [
            { id: 41, label: 'Canal' },
            { id: 42, label: 'Fort-Rolland' },
            { id: 43, label: 'J-Émery-Provost' },
        ],
    },
    {
        id: 5,
        label: 'LaSalle',
        districts: [
            { id: 51, label: 'Cecil-P.-Newman' },
            { id: 52, label: 'Sault-Saint-Louis' },
        ],
    },
    {
        id: 6,
        label: "L'Île-BizardSainte-Geneviève",
        districts: [
            { id: 61, label: 'Denis-Benjamin-Viger' },
            { id: 62, label: 'Jacques-Bizard' },
            { id: 63, label: 'Pierre-Foretier' },
            { id: 64, label: 'Sainte-Geneviève' },
        ],
    },
    {
        id: 7,
        label: 'MercierHochelaga-Maisonneuve',
        districts: [
            { id: 71, label: 'Hochelaga' },
            { id: 72, label: 'Louis-Riel' },
            { id: 73, label: 'Maisonneuve-Longue-Pointe' },
            { id: 74, label: 'Tétreaultville' },
        ],
    },
    {
        id: 8,
        label: 'Montréal-Nord',
        districts: [
            { id: 81, label: 'Marie-Clarac' },
            { id: 82, label: 'Ovide-Clermont' },
        ],
    },
    {
        id: 9,
        label: 'Outremont',
        districts: [
            { id: 91, label: 'Claude-Ryan' },
            { id: 92, label: 'Jeanne-Sauvé' },
            { id: 93, label: 'Joseph-Beaubien' },
            { id: 94, label: 'Robert-Bourassa' },
        ],
    },
    {
        id: 10,
        label: 'Pierrefonds-Roxboro',
        districts: [
            { id: 101, label: 'Bois-de-Liesse' },
            { id: 102, label: 'Cap-Saint-Jacques' },
        ],
    },
    {
        id: 11,
        label: 'Plateau-Mont-Royal',
        districts: [
            { id: 111, label: 'DeLorimier' },
            { id: 112, label: 'Jeanne-Mance' },
            { id: 113, label: 'Mile-End' },
        ],
    },
    {
        id: 12,
        label: 'Rivière-des-PrairiesPointe-aux-Trembles',
        districts: [
            { id: 121, label: 'La-Pointe-aux-Prairies' },
            { id: 122, label: 'Pointe-aux-Trembles' },
            { id: 123, label: 'Rivière-des-Prairies' },
        ],
    },
    {
        id: 13,
        label: 'RosemontLa Petite-Patrie',
        districts: [
            { id: 131, label: 'Étienne-Desmarteau' },
            { id: 132, label: 'Marie-Victorin' },
            { id: 133, label: 'Saint-Édouard' },
            { id: 134, label: 'Vieux-Rosemont' },
        ],
    },
    {
        id: 14,
        label: 'Saint-Laurent',
        districts: [
            { id: 141, label: 'Côte-de-Liesse' },
            { id: 142, label: 'Norman-McLaren' },
        ],
    },
    {
        id: 15,
        label: 'Saint-Léonard',
        districts: [
            { id: 151, label: 'Saint-Léonard Est' },
            { id: 152, label: 'Saint-Léonard Ouest' },
        ],
    },
    {
        id: 16,
        label: 'Sud-Ouest',
        districts: [
            {
                id: 161,
                label: 'Saint-Henri-EstPetite-BourgognePointe-Saint-CharlesGriffintown',
            },
            { id: 162, label: 'Saint-PaulÉmardSaint-Henri-Ouest' },
        ],
    },
    {
        id: 17,
        label: 'Verdun',
        districts: [
            { id: 171, label: "Champlain-L'Île-des-Sœurs" },
            { id: 172, label: 'Desmarchais-Crawford' },
        ],
    },
    {
        id: 18,
        label: 'Ville-Marie',
        districts: [
            { id: 181, label: 'Peter-McGill' },
            { id: 182, label: 'Sainte-Marie' },
            { id: 183, label: 'Saint-Jacques' },
        ],
    },
    {
        id: 19,
        label: 'VilleraySaint-MichelParc-Extension',
        districts: [
            { id: 191, label: 'François-Perrault' },
            { id: 192, label: 'Parc-Extension' },
            { id: 193, label: 'Saint-Michel' },
            { id: 194, label: 'Villeray' },
        ],
    },
]
