import { Header, Helmet, Table } from 'components'
import { useSelector } from 'react-redux'

function LogPage() {
    const user = useSelector(state => state.user)

    return (
        <div className='log-page'>
            <Helmet title='Journal de bord' />
            <Header title='Journal de bord' subtitle='Administration' />

            <Table instance='logs' user={user} />
        </div>
    )
}

export default LogPage
