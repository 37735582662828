import ReactCountUp from "react-countup";
import { useLocation } from "react-router-dom";
import { isPrint } from "utils";

function CountUp({ cName, children: value, percent }) {
  const printMode = isPrint(useLocation());
  return (
    <ReactCountUp
      className={cName}
      separator=" "
      decimal=","
      end={value}
      preserveValue
      suffix={percent && " %"}
      duration={printMode && 0.05}
    />
  );
}

export default CountUp;
