import Helmet from "react-helmet";
import { APP_NAME, APP_SEPARATOR } from "constants/app";

const SDSHelmet = (props) => {
  return (
    <Helmet>
      {props.title && (
        <title>{`${APP_NAME} ${APP_SEPARATOR} ${props.title}`}</title>
      )}
      {props.children}
    </Helmet>
  );
};

export default SDSHelmet;
