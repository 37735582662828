import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider } from 'antd'
import { Helmet, Icon, Logo } from 'components'
import { EDIT, READ } from 'constants/misc'
import { NavLink, useLocation } from 'react-router-dom'
import { Can, isPrint, ls } from 'utils'

function Nav() {
    const printMode = isPrint(useLocation())
    const storedIsCollapsed = ls.get('nav-collapsed') === true

    const [collapsed, setCollapsed] = useState(printMode ? false : storedIsCollapsed)
    const user = useSelector(state => state.user)

    const handleCollapsedChange = val => {
        setCollapsed(val)
        ls.set('nav-collapsed', val)
    }

    return (
        <nav className={`page-nav ${collapsed || !user.logged ? 'small' : ''}`}>
            <Helmet>
                <body data-collapsed-nav={collapsed} />
            </Helmet>

            <div className='logo-wrap'>
                {user.logged && (
                    <Button onClick={() => handleCollapsedChange(!collapsed)}>
                        <Icon>back</Icon>
                        <Icon>menu</Icon>
                        <Icon>to</Icon>
                        <i>Menu</i>
                    </Button>
                )}
                <Logo link />
            </div>

            <div className='links'>
                {/* <div>
            <p>Tableau de bord</p>
            <ul>
            {links.public.map((link, i) => (
              <li key={i}>
              <NavLink to={link.to} exact>
              <Icon>{link.icon}</Icon>
              <i>{link.label}</i>
              </NavLink>
              </li>
              ))}
              </ul>
            </div> */}

                <>
                    {/* <Divider /> */}
                    <div>
                        <p>Administration</p>
                        <ul>
                            {links.admin.map((link, i) => (
                                <Can do={EDIT} on={link.access} key={i}>
                                    <li key={i}>
                                        <NavLink to={link.to}>
                                            <Icon>{link.icon}</Icon>
                                            <i>{link.label}</i>
                                        </NavLink>
                                    </li>
                                </Can>
                            ))}
                        </ul>
                    </div>
                </>
            </div>
        </nav>
    )
}

const links = {
    public: [
        // {
        //   icon: "home_page",
        //   label: "Accueil",
        //   to: "/",
        // },
        // {
        //   icon: "demography_page",
        //   label: "Profil des personnes",
        //   to: "/stats/profil",
        // },
        // {
        //   icon: "insert_chart",
        //   label: "Données des interventions",
        //   to: "/stats/donnees",
        // },
        // {
        //   icon: "location",
        //   label: "Lieux de interventions",
        //   to: "/stats/lieux",
        // },
        // {
        //   icon: "intervention_page",
        //   label: "Compléter un rapport",
        //   to: "/interventions/new",
        // },
    ],
    admin: [
        {
            icon: 'home_page',
            label: 'Accueil',
            to: '/admin/home',
            access: 'Intervention',
        },
        {
            icon: 'demography_page',
            label: 'Profil des personnes',
            to: '/stats/profil',
            access: 'Intervention',
        },
        {
            icon: 'insert_chart',
            label: 'Données des interventions',
            to: '/stats/donnees',
            access: 'Intervention',
        },
        {
            icon: 'location',
            label: 'Lieux de interventions',
            to: '/stats/lieux',
            access: 'Intervention',
        },
        {
            icon: 'log_page',
            label: 'Journal des interventions',
            to: '/admin/logs',
            access: 'Portal',
        },
        {
            icon: 'intervention_page',
            label: 'Compléter un rapport',
            to: '/interventions/new',
            access: 'Log',
        },
        {
            icon: 'management_page',
            label: 'Gestion du site',
            to: '/admin/management',
            access: 'Portal',
        },
        // {
        //   icon: "log_page",
        //   label: "Journal de bord",
        //   to: "/admin/log",
        //   access: "Log",
        // },
        // {
        //   icon: "intervention_page",
        //   label: "Compléter un rapport",
        //   to: "/interventions/new",
        //   access: "Intervention",
        // },
    ],
}

export default Nav
