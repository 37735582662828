import React, { Component } from 'react'
import { Slider } from 'antd'

/**
 * Component : TableFilters > NumberFloatingFilter
 * ---
 * Component that renders a number floating filter (slider).
 * @prop    { Number }  min     The min value of the slider
 * @prop    { Number }  max     The max value of the slider
 * @prop    { Number }  step    The step prop of the slider
 */

class NumberFloatingFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            minValue: props.min || 0,
            maxValue: props.max || 100,
            stepValue: props.step || 1,
            currentValue: null,
        }

        this.valueChanged = this.valueChanged.bind(this)
        this.onParentModelChanged = this.onParentModelChanged.bind(this)
    }

    // Filter's value change.
    valueChanged(value) {
        this.setState({ currentValue: value }, () => {
            let valueToUse = JSON.stringify(this.state.currentValue)
            if (
                this.state.currentValue[0] === this.state.minValue &&
                this.state.currentValue[1] === this.state.maxValue
            )
                valueToUse = null
            this.props.parentFilterInstance(instance =>
                instance.onFloatingFilterChanged('equals', valueToUse)
            )
        })
    }

    // Called when the table gives a new value to display here.
    onParentModelChanged(parentModel) {
        let currentValue = null
        if (parentModel) {
            let value = JSON.parse(parentModel.filter)
            if (Array.isArray(value)) currentValue = value
        }

        this.setState({ currentValue })
    }

    render() {
        let slider = (key, value) => (
            <Slider
                key={key}
                range
                defaultValue={value}
                min={this.state.minValue}
                max={this.state.maxValue}
                step={this.state.stepValue}
                onAfterChange={this.valueChanged}
            />
        )

        // Skecthy logic because the Antd Slider works best when
        // only bound by a defaultValue and not the actual value.
        // Must display empty slider when filter is inactive.
        if (!this.state.currentValue)
            return slider('empty', [this.state.minValue, this.state.maxValue])
        return slider('full', this.state.currentValue)
    }
}

export default NumberFloatingFilter
